/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ClickAwayListener,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import { User } from 'src/pages/User';
import logo from '../../assets/logo-brasao-bpm.svg';
import { useAuth } from '../../context/AuthContext';
import { useMenu } from '../../context/MenuContext';
import { TriggerIcon } from '../../assets/menuicon/TriggerIcon';
import { TriggersTimeline } from '../TriggersTimeline';
import { useTrigger } from '../../context/TriggerContext';
import { COLORS } from '../../styles/colors';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { FirstLoginDialog } from '../FirstLoginDialog';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: 'solid 1px #DCDCDC',
    '@media (max-width: 500px)': {
      maxHeight: '64px',
      maxWidth: '100%',
      overflow: 'hidden',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    '@media (max-width: 500px)': {
      justifyContent: 'start',
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
  logo: {
    height: 32,
  },
  rightBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '2px',
    '@media (max-width: 500px)': {
      flexWrap: 'nowrap',
      maxWidth: '150px',
      margin: 0,
      marginLeft: '1rem',
    },
  },
  userMsg: {
    fontSize: '15px',
    color: 'rgba(0, 0, 0, 0.54)',
    '@media (max-width: 500px)': {
      flexBasis: '100%',
      textOverflow: 'ellipsis',
      display: 'none',
    },
  },
  buttonBox: {
    display: 'flex',
    flexWrap: 'nowrap',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
}));

export const HeaderMenu: React.FC = () => {
  const { signOut, user } = useAuth();
  const { handleMenuIsOpen } = useMenu();
  const { hasNewTriggers, setHasNewTriggers, triggersData } = useTrigger();

  const handleDrawer = useCallback(() => {
    handleMenuIsOpen();
  }, [handleMenuIsOpen]);

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isFirstLoginDialogOpen, setIsFirstLoginDialogOpen] = useState(false);
  const [confirmedChangePassword, setConfirmedCangePassword] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickAway = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const toggleTriggerTimeline = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
    setHasNewTriggers(false);
  };

  const handleOpenUserModal = () => {
    setIsUserModalOpen(true);
  };

  const handleConfirmChangePassword = () => {
    setIsFirstLoginDialogOpen(false);
    setConfirmedCangePassword(true);
    handleOpenUserModal();
  };

  const handleCloseUserModal = () => {
    setIsUserModalOpen(false);
  };

  useEffect(() => {
    if (user.isFirstLogin) {
      setIsFirstLoginDialogOpen(true);
    }
  }, [user]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <AppBar color="inherit" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/dashboard">
              <img src={logo} alt="logo" className={classes.logo} />
            </Link>
          </div>
          <div className={classes.rightBox}>
            <Avatar
              onClick={handleOpenUserModal}
              className="mr-2 hover:cursor-pointer"
            >
              <AvatarImage src={user.avatarUrl || ''} />
              <AvatarFallback>
                {`${user.name ? user.name.slice(0, 2).toUpperCase() : ''}`}
              </AvatarFallback>
            </Avatar>
            <Typography className={classes.userMsg} variant="h6">
              Olá, {user.name}
            </Typography>
            <div className={classes.buttonBox}>
              <Tooltip
                title="Timeline de gatilhos executados"
                placement="bottom-end"
              >
                <IconButton
                  style={
                    hasNewTriggers
                      ? { color: COLORS.GREEN, position: 'relative' }
                      : { position: 'relative' }
                  }
                  onClick={toggleTriggerTimeline}
                  disabled={
                    !triggersData || (triggersData && triggersData.length === 0)
                  }
                >
                  <TriggerIcon />
                </IconButton>
              </Tooltip>
              {isOpen && anchorEl && (
                <TriggersTimeline
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  anchorEl={anchorEl}
                />
              )}
              {/* <IconButton>
                <NotificationsIcon />
              </IconButton> */}
              <IconButton onClick={() => signOut()}>
                <PowerSettingsNew />
              </IconButton>
            </div>
          </div>

          {isFirstLoginDialogOpen && user.id !== undefined && (
            <FirstLoginDialog
              open={isFirstLoginDialogOpen}
              setOpen={setIsFirstLoginDialogOpen}
              handleConfirm={handleConfirmChangePassword}
            />
          )}
          {!isFirstLoginDialogOpen &&
            isUserModalOpen &&
            user.id !== undefined && (
              <User
                closeModal={handleCloseUserModal}
                userIdProp={user.id}
                open={isUserModalOpen}
                confirmedChangePassword={confirmedChangePassword}
              />
            )}
        </Toolbar>
      </AppBar>
    </ClickAwayListener>
  );
};
