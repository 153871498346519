export const validateUserStats = (
  isActive: boolean,
  inactiveStartsAt: string | null,
  inactiveEndsAt: string | null,
) => {
  const newDate = new Date().toISOString().split('T')[0];
  const todayDate = new Date(newDate);
  const newInactiveStartsAt = new Date(inactiveStartsAt || '');
  const newInactiveEndsAt = new Date(inactiveEndsAt || '');

  if (isActive === false) {
    return 'Inativo';
  }

  if (
    inactiveStartsAt &&
    inactiveEndsAt &&
    todayDate >= newInactiveStartsAt &&
    todayDate <= newInactiveEndsAt
  ) {
    return 'Inativo Período';
  }

  return 'Ativo';
};
