import React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog';
import { useAuth } from 'src/context/AuthContext';

interface AlertDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleConfirm: () => void;
}

export const FirstLoginDialog: React.FC<AlertDialogProps> = ({
  open,
  setOpen,
  handleConfirm,
}) => {
  const { user, updateUser } = useAuth();

  const handleCloseDialog = () => {
    updateUser({ ...user, isFirstLogin: false });
    setOpen(false);
  };

  const handleConfirmClick = () => {
    updateUser({ ...user, isFirstLogin: false });
    handleConfirm();
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={status => {
        if (!status) handleCloseDialog();
      }}
    >
      <AlertDialogOverlay className="z-[1500]">
        <AlertDialogContent
          autoFocus
          onKeyDown={e => {
            if (open && e.key === 'Escape') handleCloseDialog();
          }}
        >
          <AlertDialogHeader>
            <AlertDialogTitle className="flex flex-row items-center text-BPM_PRIMARYLIGHT">
              Boas-vindas ao Brasão BPM!
            </AlertDialogTitle>
            <AlertDialogDescription>
              <p>
                Identificamos que esse é seu primeiro acesso, e para garantir a
                segurança da sua conta, recomendamos que você altere sua senha.
              </p>
              <p>
                Isso ajudará a proteger suas informações pessoais e manter sua
                conta segura.
              </p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCloseDialog}>
              Fechar
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmClick}>
              Alterar senha
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
