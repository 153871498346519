/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { IntegratedItemFormat } from 'src/components/ModalIntegratedCustomFields';
import { Button } from 'src/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { Input } from 'src/components/ui/input';
import { Skeleton } from 'src/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import api from 'src/services/api';
import {
  getFormatAlignValue,
  integrationFormat,
} from 'src/utils/integratedFieldUtils';
import { toastLoadingUpdate } from 'src/utils/toastifyUtils';

interface IModalCustomProps {
  openModal: boolean;
  customField_id: string;
  customField_name: string;
  format: integrationFormat;
  dynamicFormat: integrationFormat;
  primaryKeyColumnName: string;
  handleCloseModal: () => void;
  onSelectedIntegratedItem: (value: string) => void;
}

export const ConditionIntegratedFieldModal: React.FC<IModalCustomProps> = ({
  openModal,
  customField_id,
  customField_name,
  format,
  dynamicFormat,
  primaryKeyColumnName,
  handleCloseModal,
  onSelectedIntegratedItem,
}) => {
  const hasFormat = format !== null && format !== undefined;
  const hasDynamicFormat =
    dynamicFormat !== null && dynamicFormat !== undefined;
  const currentFormat: Record<string, IntegratedItemFormat> | null = hasFormat
    ? format
    : dynamicFormat;
  const [requestError, setRequestError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [originalRows, setOriginalRows] = useState<Record<string, unknown>[]>(
    [],
  );
  const [integratedItemList, setIntegratedItemList] = useState<
    Record<string, unknown>[]
  >([]);
  const [integratedItemSelected, setIntegratedItemSelected] = useState<Record<
    string,
    unknown
  > | null>(null);
  const [tableColumns, setTableColumns] = useState<IntegratedItemFormat[]>([]);

  useEffect(() => {
    if ((hasFormat || hasDynamicFormat) && currentFormat !== null) {
      const formatEntries = Object.entries(currentFormat)
        .sort(
          (entryA: any, entryB: any) =>
            Number(entryA[1].order) - Number(entryB[1].order),
        )
        .map((f: any) => f[1]);

      setTableColumns(formatEntries);
    }
  }, [format, dynamicFormat]);

  useEffect(() => {
    async function getIntegrationData() {
      const loadingData = toast.loading(`Buscando dados da integração`, {
        autoClose: 5000,
      });
      document.body.style.cursor = 'progress';
      await api
        .post(`/integrations/integrated_items/customField/${customField_id}`)
        .then(response => {
          if (response.data.length === 0) {
            toast.warning('Nenhum dado corresponde a pesquisa', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 3000,
            });
          }
          if (response.data) {
            const dataResp = response?.data;
            const sortedColumns =
              currentFormat !== null
                ? Object.entries(currentFormat)
                    .sort(
                      (entryA: any, entryB: any) =>
                        Number(entryA[1].order) - Number(entryB[1].order),
                    )
                    .map((f: any) => {
                      if (hasDynamicFormat) return f[1]?.name || f[0];
                      return f[0];
                    })
                : [];

            // Create new itens in correct column order
            const newOrderedList = dataResp.map((item: any) => {
              const row: any = {};
              sortedColumns.forEach((column: any) => {
                row[column] = item[column] || '';
              });
              return row;
            });

            setOriginalRows(newOrderedList);
            setIntegratedItemList(newOrderedList);
          }
          toastLoadingUpdate(loadingData, ``, 'success', 500);
        })
        .catch(error => {
          let newError = error?.response?.data?.message || '';
          if (error.message === 'Request failed with status code 422') {
            newError = `Nenhum dado encontrado`;
            toast.warning(newError, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'dark',
              autoClose: 4000,
              closeOnClick: true,
            });
          }
          if (error.message === 'Request failed with status code 404') {
            newError = `Erro na conexão`;
          }
          toastLoadingUpdate(loadingData, `Erro. ${newError}`, 'error', 4000);
          setRequestError(newError);
        })
        .finally(() => {
          document.body.style.cursor = 'auto';
          setLoadingData(false);
        });
    }

    if (customField_id) {
      getIntegrationData();
    }
  }, [customField_id]);

  useEffect(() => {
    function requestSearch(searchVal: string) {
      const filteredRows = originalRows.filter(field => {
        return Object.values(field)
          .toString()
          .toLowerCase()
          .includes(searchVal.toLowerCase());
      });
      setIntegratedItemList(filteredRows);
    }
    requestSearch(searchTerm);
  }, [searchTerm, originalRows]);

  function handleOnSelectIntegratedItem(
    selectedIntegratedItem: Record<string, unknown>,
  ) {
    setIntegratedItemSelected(selectedIntegratedItem);
  }

  const handleOnConfirmSelection = () => {
    if (
      primaryKeyColumnName &&
      integratedItemSelected &&
      integratedItemSelected[primaryKeyColumnName] &&
      typeof integratedItemSelected[primaryKeyColumnName] === 'string'
    ) {
      const newValue: string =
        (integratedItemSelected[primaryKeyColumnName] as string) || '';
      onSelectedIntegratedItem(newValue);
      handleCloseModal();
    }
  };

  const hiddenColumns: string[] =
    hasDynamicFormat && currentFormat
      ? Object.entries(currentFormat)
          .filter(([, value]) => value?.isHidden)
          .map(([, value]) => value.name)
      : [];

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        if (openModal) handleCloseModal();
      }}
      modal
    >
      <DialogContent
        onKeyDown={e => {
          e.stopPropagation();
          if (openModal && e.key === 'Escape') {
            handleCloseModal();
          }
        }}
        onClick={e => e.stopPropagation()}
        onEscapeKeyDown={e => e.stopPropagation()}
        onPointerDownOutside={e => e.stopPropagation()}
        className="z-[2000] min-w-[800px] max-md:min-w-full h-full flex flex-col"
        autoFocus
      >
        <DialogHeader>
          <DialogTitle>
            {customField_name || 'Pesquisa de Campo Integrado'}
          </DialogTitle>
        </DialogHeader>
        <div className="h-full w-full">
          <div className="flex items-center justify-between my-4 p-1">
            <Input
              placeholder="Pesquisar"
              name="searchIntegrateValue"
              autoFocus
              value={searchTerm || ''}
              onChange={e => setSearchTerm(e.target.value)}
              className="max-w-[50%]"
              disabled={loadingData}
            />
            <Button
              onClick={() => handleOnConfirmSelection()}
              disabled={integratedItemSelected === null}
            >
              Selecionar
            </Button>
          </div>
          {loadingData ? (
            <div>
              <span className="font-semibold">
                Aguarde. Carregando dados...
              </span>
              <Skeleton className="h-9 w-full mt-1" />
              <div className="space-y-2">
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
              </div>
            </div>
          ) : (
            <div className="overflow-auto box-border border-2 rounded-md max-h-[80vh]">
              <Table className="w-full h-auto m-0 p-0">
                <TableHeader className="sticky -top-0.5">
                  <TableRow>
                    {(format || dynamicFormat) &&
                      tableColumns.map((column: IntegratedItemFormat) => {
                        if (
                          dynamicFormat &&
                          hiddenColumns.length > 0 &&
                          hiddenColumns.includes(column?.name)
                        ) {
                          return null;
                        }

                        return (
                          <TableHead
                            align={column?.align as any}
                            key={column?.name}
                            className="py-1.5 h-fit w-fit font-semibold text-nowrap"
                          >
                            {column?.name}
                          </TableHead>
                        );
                      })}
                  </TableRow>
                </TableHeader>
                <TableBody className="m-0">
                  {integratedItemList && integratedItemList.length > 0 ? (
                    integratedItemList.map(
                      (
                        integratedItem: Record<string, unknown>,
                        index: number,
                      ) => {
                        return (
                          <TableRow
                            key={
                              (integratedItem?.[
                                primaryKeyColumnName
                              ] as string) || `row${index}`
                            }
                            className={`cursor-pointer ${
                              integratedItemSelected &&
                              primaryKeyColumnName &&
                              integratedItemSelected?.[primaryKeyColumnName] ===
                                integratedItem?.[primaryKeyColumnName]
                                ? 'bg-primary text-white hover:bg-primary hover:text-white'
                                : ''
                            }`}
                            onClick={() => {
                              handleOnSelectIntegratedItem(integratedItem);
                            }}
                          >
                            {Object.entries(integratedItem).map(
                              ([columnKey, field], cellIndex) => {
                                if (
                                  dynamicFormat &&
                                  hiddenColumns.length > 0 &&
                                  hiddenColumns.includes(columnKey)
                                ) {
                                  return null;
                                }

                                return (
                                  <TableCell
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${integratedItem[primaryKeyColumnName]}-${field}-${cellIndex}}`}
                                    align={
                                      getFormatAlignValue(
                                        columnKey,
                                        tableColumns,
                                        currentFormat,
                                        hasFormat,
                                      ) as any
                                    }
                                    className="py-1 text-nowrap"
                                  >
                                    {typeof field === 'boolean'
                                      ? field
                                        ? 'Sim'
                                        : 'Não'
                                      : field || ''}
                                  </TableCell>
                                );
                              },
                            )}
                          </TableRow>
                        );
                      },
                    )
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={tableColumns.length}
                        className="text-center"
                      >
                        Sem resultados.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </div>

        {requestError.length > 0 && (
          <>
            <p className="text-center font-medium">{requestError}</p>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
