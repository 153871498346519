import React from 'react';

import {
  Box,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TextField,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  HandleEditIntegrationFieldProps,
  IIntegrationFieldValue,
  IIntegrationTypeField,
  IIntegrationTypeFieldsProps,
} from '../IntegrationRules.i';
import { CustomPopper } from '../../../components/AutoCompleteCustomComponents/CustomPopper';
import { ObjectKeyOptions } from './ObjectKeyOptions';

const IntegrationTypeFields: React.FC<IIntegrationTypeFieldsProps> = ({
  integrationTypeFields,
  integrationFieldValues,
  associatedFieldOptions,
  customFields,
  setIntegrationFieldValues,
}) => {
  const handleIntegrationFieldValue = ({
    typeFieldId,
    fieldType,
    requiresFullIntegratedItem,
    valueId,
    valueType,
    valueFieldType,
  }: HandleEditIntegrationFieldProps) => {
    const newFieldValue = integrationFieldValues.find(
      field => field.integrationTypeFieldId === typeFieldId,
    );

    if (newFieldValue) {
      const newIntegrationFieldValues = integrationFieldValues.map(
        (field: IIntegrationFieldValue) => {
          if (field.integrationTypeFieldId === typeFieldId) {
            return {
              integrationTypeFieldId: typeFieldId,
              fieldId: valueId,
              fixedValue: '',
              isCustomField: valueFieldType === 'customField',
              isAccountField: valueFieldType === 'accountField',
              integratedColumnName:
                fieldType !== 'INTEGRATED' &&
                valueType === 'INTEGRATED' &&
                requiresFullIntegratedItem === false
                  ? associatedFieldOptions.find(af => af.id === valueId)
                      ?.primaryKeyColumnName || ''
                  : undefined,
            };
          }

          return field;
        },
      ) as IIntegrationFieldValue[];

      const isIntegratedParentField = integrationTypeFields.find(
        typeField =>
          typeField.parent_id === typeFieldId &&
          typeField.fieldType === 'INTEGRATED',
      );

      if (fieldType === 'TABLE' || isIntegratedParentField) {
        const tableTypeFieldsIds = integrationTypeFields
          .filter(typeField => typeField.parent_id === typeFieldId)
          .map(t => t.id);
        const tableFieldsValues = newIntegrationFieldValues.filter(tField => {
          if (!tableTypeFieldsIds.includes(tField.integrationTypeFieldId))
            return true;

          return false;
        });
        setIntegrationFieldValues(tableFieldsValues);
      } else {
        setIntegrationFieldValues(newIntegrationFieldValues);
      }
    } else {
      setIntegrationFieldValues((oldState: IIntegrationFieldValue[]) => [
        ...oldState,
        {
          integrationTypeFieldId: typeFieldId,
          fieldId: valueId,
          fixedValue: '',
          isCustomField: valueFieldType === 'customField',
          isAccountField: valueFieldType === 'accountField',
          integratedColumnName:
            fieldType !== 'INTEGRATED' &&
            valueType === 'INTEGRATED' &&
            requiresFullIntegratedItem === false
              ? associatedFieldOptions.find(af => af.id === valueId)
                  ?.primaryKeyColumnName || ''
              : undefined,
        },
      ]);
    }
  };

  const handleIntegrationRulesFixedValue = (
    typeFieldId: string,
    value: string,
  ) => {
    const newFieldValue = integrationFieldValues.find(
      field => field.integrationTypeFieldId === typeFieldId,
    );

    if (newFieldValue) {
      const newIntegrationFieldValues = integrationFieldValues.map(
        (field: IIntegrationFieldValue) => {
          if (field.integrationTypeFieldId === typeFieldId) {
            return {
              integrationTypeFieldId: typeFieldId,
              fieldId: '',
              fixedValue: value,
              isCustomField: false,
              isAccountField: false,
              integratedColumnName: undefined,
            };
          }

          return field;
        },
      ) as IIntegrationFieldValue[];
      setIntegrationFieldValues(newIntegrationFieldValues);
    } else {
      setIntegrationFieldValues((oldState: IIntegrationFieldValue[]) => [
        ...oldState,
        {
          integrationTypeFieldId: typeFieldId,
          fieldId: '',
          fixedValue: value,
          isCustomField: false,
          isAccountField: false,
          integratedColumnName: undefined,
        },
      ]);
    }
  };

  const filterOptions = (integrationField: IIntegrationTypeField) => {
    const newOption = (
      integrationField.fieldType === 'TABLE'
        ? associatedFieldOptions.filter(option => option.type === 'TABLE')
        : integrationField?.parent &&
          integrationField.parent?.fieldType === 'TABLE'
        ? associatedFieldOptions.filter(customField => {
            const tableFieldValue = integrationFieldValues.find(
              tField =>
                integrationField.parent_id === tField.integrationTypeFieldId &&
                tField.fieldId,
            );

            if (tableFieldValue) {
              if (!integrationField.requiresFullIntegratedItem) {
                return customField.tableId === tableFieldValue.fieldId;
              }
              return (
                customField.type === 'INTEGRATED' &&
                customField.tableId === tableFieldValue.fieldId
              );
            }

            return false;
          })
        : integrationField.requiresFullIntegratedItem ||
          integrationField.fieldType === 'INTEGRATED'
        ? associatedFieldOptions.filter(
            customField =>
              customField.type === 'INTEGRATED' && !customField.tableId,
          )
        : associatedFieldOptions.filter(
            customField => customField.type !== 'TABLE' && !customField.tableId,
          )
    ).sort((a, b) => -b.phase.localeCompare(a.phase));
    return newOption;
  };

  const returnObjectFormat = (parentField: IIntegrationTypeField) => {
    const { id } = parentField;
    const parentValueId = integrationFieldValues.find(
      value => value.integrationTypeFieldId === id,
    )?.fieldId;
    const parentCustomField = associatedFieldOptions.find(
      field => field.id === parentValueId,
    );
    let newOption: string[] = [];

    if (
      parentCustomField &&
      parentCustomField?.hasDynamicFormat &&
      parentCustomField?.dynamicFormat
    ) {
      newOption =
        Object.keys(parentCustomField?.dynamicFormat).sort(
          (a, b) => -b.localeCompare(a),
        ) || [];
    }

    if (parentCustomField && parentCustomField?.columnFormats) {
      newOption =
        Object.keys(parentCustomField?.columnFormats).sort(
          (a, b) => -b.localeCompare(a),
        ) || [];
    }

    return newOption;
  };

  const isFieldInReturnObjectFormat = (
    integrationTypeField: IIntegrationTypeField,
  ): boolean => {
    if (!integrationTypeField || !integrationTypeField.parent) return false;
    const fieldId = integrationFieldValues.find(
      value => value.integrationTypeFieldId === integrationTypeField.id,
    )?.fieldId;
    const formattedObject = integrationTypeField.parent
      ? returnObjectFormat(integrationTypeField.parent)
      : [];
    return !formattedObject.includes(fieldId || '');
  };

  return (
    <Box marginTop="20px">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="lista de campos">
          <TableHead>
            <TableRow>
              <TableCell>Campo ERP</TableCell>
              <TableCell>Campo a associar</TableCell>
              <TableCell>Valor Fixo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrationTypeFields.map(integrationTypeField => {
              const isFieldFromIntegratedParent =
                integrationTypeField.fieldType === 'FIELD' &&
                integrationTypeField?.parent &&
                integrationTypeField?.parent?.fieldType === 'INTEGRATED';

              const isFieldInAssociatedOptions = (): boolean => {
                const fieldId = integrationFieldValues.find(
                  value =>
                    value.integrationTypeFieldId === integrationTypeField.id,
                )?.fieldId;
                return associatedFieldOptions.some(
                  field => field.id === fieldId,
                );
              };

              const shouldValidateWithAssociatedOptions = (): boolean =>
                !integrationTypeField?.parent ||
                integrationTypeField.fieldType !== 'FIELD';

              const fieldParentName = integrationTypeField?.parentName || '';

              return (
                <TableRow key={integrationTypeField.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={
                      integrationTypeField.nestingLevel > 0
                        ? {
                            paddingLeft: `${
                              integrationTypeField.nestingLevel + 0.5
                            }rem`,
                          }
                        : undefined
                    }
                  >
                    {`${
                      integrationTypeField.parent_id
                        ? `- ${integrationTypeField.name}`
                        : integrationTypeField.name
                    }${
                      fieldParentName?.length > 0 ? ` (${fieldParentName})` : ''
                    }`}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {integrationTypeField.fieldType === 'FIELD' &&
                      integrationTypeField?.parent &&
                      integrationTypeField?.parent.fieldType ===
                        'INTEGRATED' && (
                        <ObjectKeyOptions
                          integrationTypeField={integrationTypeField}
                          integrationFieldValues={integrationFieldValues}
                          handleIntegrationFieldValue={
                            handleIntegrationFieldValue
                          }
                          options={returnObjectFormat(
                            integrationTypeField.parent,
                          )}
                        />
                      )}
                    {associatedFieldOptions.length > 0 &&
                      (!integrationTypeField?.parent ||
                        (integrationTypeField?.parent &&
                          integrationTypeField?.parent.fieldType !==
                            'INTEGRATED')) && (
                        <Autocomplete
                          id={integrationTypeField.id}
                          fullWidth
                          style={{ minWidth: '200px' }}
                          getOptionLabel={i =>
                            i?.tableName
                              ? `${i.name} - ${i.tableName}`
                              : i.name || ''
                          }
                          getOptionSelected={(i, v) => i?.id === v?.id}
                          options={filterOptions(integrationTypeField)}
                          groupBy={option =>
                            (option.phase as string) ?? 'Contas'
                          }
                          PopperComponent={CustomPopper}
                          value={
                            associatedFieldOptions[
                              associatedFieldOptions.findIndex(
                                field =>
                                  integrationFieldValues.find(
                                    integrationFieldValue =>
                                      integrationFieldValue.integrationTypeFieldId ===
                                      integrationTypeField.id,
                                  )?.fieldId === field.id,
                              )
                            ] || null
                          }
                          onChange={(e, value) => {
                            handleIntegrationFieldValue({
                              typeFieldId: integrationTypeField.id,
                              fieldType: integrationTypeField.fieldType,
                              requiresFullIntegratedItem: value
                                ? integrationTypeField.requiresFullIntegratedItem
                                : false,
                              valueId: value ? value.id : '',
                              valueType: value ? value.type : '',
                              valueFieldType: value
                                ? value.fieldType
                                : undefined,
                            });
                          }}
                          noOptionsText={
                            integrationTypeField.fieldType !== 'TABLE' &&
                            integrationTypeField.parent_id &&
                            customFields
                              ? 'Sem opções ou campo pai não associado.'
                              : 'Sem opções.'
                          }
                          loadingText="Carregando"
                          renderInput={rest => (
                            <TextField
                              {...rest}
                              required={
                                integrationTypeField.isRequired &&
                                !integrationFieldValues.find(
                                  integrationFieldValue =>
                                    integrationFieldValue.integrationTypeFieldId ===
                                    integrationTypeField.id,
                                )?.fixedValue
                              }
                              label="Associação"
                              margin="dense"
                              InputProps={{
                                ...rest.InputProps,
                              }}
                            />
                          )}
                        />
                      )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <TextField
                      label="Valor Fixo"
                      fullWidth
                      onChange={value =>
                        handleIntegrationRulesFixedValue(
                          integrationTypeField?.id,
                          value.target.value,
                        )
                      }
                      value={
                        integrationFieldValues.find(
                          integrationFieldValue =>
                            integrationFieldValue.integrationTypeFieldId ===
                            integrationTypeField.id,
                        )?.fixedValue ?? ''
                      }
                      disabled={
                        integrationTypeField.fieldType === 'TABLE' ||
                        integrationTypeField.fieldType === 'INTEGRATED' ||
                        integrationTypeField.requiresFullIntegratedItem
                      }
                      margin="dense"
                      required={
                        integrationTypeField.isRequired &&
                        integrationTypeField.fieldType !== 'TABLE' &&
                        integrationTypeField.fieldType !== 'INTEGRATED' &&
                        ((isFieldFromIntegratedParent &&
                          isFieldInReturnObjectFormat(integrationTypeField)) ||
                          (shouldValidateWithAssociatedOptions() &&
                            !isFieldInAssociatedOptions()))
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IntegrationTypeFields;
