/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext } from 'react';

interface DataTableContextType {
  handleOpenModal: (id: string) => void;
  handleInactiveItem?: (id: string) => void;
  handleDeleteItem: (id: string) => void;
}

export const DataTableContext = createContext<DataTableContextType | undefined>(
  undefined,
);

export const useDataTableContext = (): DataTableContextType => {
  const context = useContext(DataTableContext);
  if (!context) {
    throw new Error(
      'useDataTableContext must be used within a DataTableContextProvider',
    );
  }
  return context;
};
