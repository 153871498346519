import React from 'react';

import { TextField } from '@material-ui/core';
import { InputPhoneMask } from 'src/components/InputPhoneMask';
import { AvatarUploader } from 'src/components/AvatarUploader';
import { useAuth } from 'src/context/AuthContext';
import { UserRestrictData } from './UserRestrictData';
import { UserRestrictDataProps } from '../User.i';

export const UserData: React.FC<UserRestrictDataProps> = ({
  formik,
  businesses,
  loadingBusiness,
  loadingArea,
  areas,
  userId,
  avatarUrl,
  confirmedChangePassword,
}) => {
  const { user } = useAuth();
  const isSameUser = userId === user.id;

  return (
    <div className="w-full flex flex-col gap-2 border-2 p-2 rounded-sm">
      <div className="w-fit text-1xl font-bold ml-1 px-1 -mt-5 bg-white">
        Dados Cadastrais
      </div>
      <div className="w-full flex flex-row max-md:flex-col">
        <div className="flex flex-col w-full gap-2 items-start p-0 m-0 max-md:flex-wrap">
          <div className="flex flex-row gap-2 items-start w-full p-0 m-0 max-md:flex-wrap">
            <TextField
              id="name"
              label="Nome"
              name="name"
              autoComplete="name"
              margin="dense"
              variant="outlined"
              fullWidth
              autoFocus={!confirmedChangePassword}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              margin="dense"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>

          <div className="flex flex-row gap-2 items-start w-full p-0 m-0 max-md:flex-wrap">
            <TextField
              id="birthDate"
              label="Data de Nascimento"
              name="birthDate"
              autoComplete="off"
              margin="dense"
              variant="outlined"
              type="date"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.birthDate || ''}
              error={
                formik.touched.birthDate && Boolean(formik.errors.birthDate)
              }
              helperText={formik.touched.birthDate && formik.errors.birthDate}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  inputformat: 'dd/mm/yyyy',
                  pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
                },
              }}
            />
            <TextField
              id="phone"
              label="Celular"
              name="phone"
              autoComplete="off"
              margin="dense"
              variant="outlined"
              type="tel"
              fullWidth
              onChange={e => {
                const { value } = e.target;
                const isEmpty =
                  !value || value.replace(/[()\s-\u2000]/g, '') === '';

                if (isEmpty) {
                  formik.setFieldValue('phone', null);
                } else {
                  formik.handleChange(e);
                }
              }}
              value={formik.values.phone || ''}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: InputPhoneMask as any,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="jobTitle"
              label="Cargo"
              name="jobTitle"
              autoComplete="off"
              margin="dense"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.jobTitle || ''}
              error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
              helperText={formik.touched.jobTitle && formik.errors.jobTitle}
            />
          </div>
        </div>
        {userId && isSameUser && (
          <div className="flex flex-col gap-3 p-0 m-0 ml-4 min-w-[300px] max-md:flex-wrap max-md:ml-0 max-md:w-1/2">
            <AvatarUploader avatarUrl={avatarUrl} />
          </div>
        )}
      </div>
      <UserRestrictData
        formik={formik}
        businesses={businesses}
        loadingBusiness={loadingBusiness}
        loadingArea={loadingArea}
        areas={areas}
      />
    </div>
  );
};
