import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Swal from 'sweetalert2';
import Box from '@material-ui/core/Box';
import { IconButton, TextField, CircularProgress } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {
  TableContainer,
  Container,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Autocomplete } from '@material-ui/lab';

import { toast } from 'react-toastify';

import { useAuth } from 'src/context/AuthContext';
import TablePaginationActions from '../../../components/TablePaginationActions';
import api from '../../../services/api';

import { ModalWithTitle } from '../../../components/ModalWithTitle';
import { Trigger } from '../index';

import { styles } from './styles';
import { filterObject, ITrigger } from '../Trigger.i';
import HeaderPage from '../../../components/TitleAndButton';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const ListTrigger: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const userCantEditTrigger = user.permissions.TRIGGER !== 'READ_AND_WRITE';
  const [openModal, setOpenModal] = useState(false);
  const [triggers, setTriggers] = useState<ITrigger[]>([]);
  const [filteredTriggers, setFilteredTriggers] = useState<ITrigger[]>([]);
  const [areas, setAreas] = useState<filterObject[]>([]);
  const [selectedArea, setSelectedArea] = useState<filterObject | null>(null);
  const [phases, setPhases] = useState<filterObject[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<filterObject | null>(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(-1);
  const [findName, setFindName] = useState('');
  const [loadingTriggers, setLoadingTriggers] = useState(true);
  const [initialized, setInitialized] = useState(true);
  const [refreshTriggers, setRefreshTriggers] = useState(false);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, triggers.length - page * rowsPerPage);

  useEffect(() => {
    api
      .get('/triggers')
      .then(response => {
        const newTriggers: ITrigger[] = response.data || [];

        const uniqueAreas: filterObject[] = newTriggers.reduce(
          (uniqueList: filterObject[], currentTrigger) => {
            const existingAreaIndex = uniqueList.findIndex(
              item => currentTrigger.area && item.id === currentTrigger.area.id,
            );

            if (existingAreaIndex === -1 && currentTrigger.area) {
              uniqueList.push({
                id: currentTrigger.area.id,
                name: currentTrigger.area.name,
              });
            }

            return uniqueList;
          },
          [],
        );

        const uniquePhases: filterObject[] = newTriggers.reduce(
          (uniqueList: filterObject[], currentTrigger) => {
            const existingPhaseIndex = uniqueList.findIndex(
              item =>
                currentTrigger.phase && item.id === currentTrigger.phase.id,
            );

            if (existingPhaseIndex === -1 && currentTrigger.phase) {
              uniqueList.push({
                id: currentTrigger.phase.id,
                name: currentTrigger.phase.name,
              });
            }

            return uniqueList;
          },
          [],
        );

        setTriggers(newTriggers);
        if (uniqueAreas.length > 0) setAreas(uniqueAreas);
        if (uniquePhases.length > 0) setPhases(uniquePhases);
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      })
      .finally(() => setLoadingTriggers(false));
  }, [refreshTriggers]);

  const updateURLParams = useCallback(() => {
    const params = new URLSearchParams();

    // Update the URL parameters individually
    if (findName !== null && findName !== '') {
      params.set('findName', findName);
    } else {
      params.delete('findName'); // Remove if null or empty
    }

    if (selectedArea !== null && selectedArea.id) {
      params.set('area', selectedArea.id);
    } else {
      params.delete('area'); // Remove if null or empty
    }

    if (selectedPhase !== null && selectedPhase.id) {
      params.set('phase', selectedPhase.id);
    } else {
      params.delete('phase'); // Remove if null or empty
    }

    history.replace({ search: params.toString() });
  }, [findName, selectedArea, selectedPhase, history]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const findName = params.get('findName') || '';
    const areaId = params.get('area');
    const phaseId = params.get('phase');

    if (!loadingTriggers || findName || areaId || phaseId) {
      setFindName(findName);
      if (areaId && areas.length > 0) {
        const area = areas.find(area => area.id === areaId);
        setSelectedArea(area || null);
      }

      if (phaseId && phases && phases.length > 0) {
        const phase = phases.find(phase => phase.id === phaseId);
        setSelectedPhase(phase || null);
      }
      setInitialized(true);
    }
  }, [location.search, loadingTriggers, areas, phases]);

  useEffect(() => {
    if (!loadingTriggers && initialized) {
      updateURLParams();
    }
  }, [findName, selectedArea, selectedPhase, updateURLParams]);

  useEffect(() => {
    setFilteredTriggers(triggers);
  }, [triggers]);

  useEffect(() => {
    const filtered = triggers.filter(
      trigger =>
        (selectedArea?.id === trigger?.area?.id || selectedArea === null) &&
        (selectedPhase?.id === trigger?.phase?.id || selectedPhase === null) &&
        trigger.name
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0,
    );

    setPage(0);
    setFilteredTriggers(filtered);
  }, [findName, selectedArea, selectedPhase, triggers]);

  const handleEdit = useCallback(
    (triggerId: string) => {
      history.push({
        pathname: `/triggers/${triggerId}`,
        search: location.search, // Preserve query parameters
      });
    },
    [history, location],
  );

  const handleDelete = useCallback((triggerId: string) => {
    Swal.fire({
      title: 'Deseja excluir?',
      text: 'Essa opção não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
      customClass: {
        container: classes.swalAlert,
      },
    }).then(async result => {
      if (result.isConfirmed) {
        await api
          .delete(`/triggers/${triggerId}`)
          .then(onfulfilled => {
            if (onfulfilled)
              toast.success('Gatilho excluído.', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              });
          })
          .catch(error => {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            });
          })
          .finally(() => {
            api.get('/triggers').then(response => setTriggers(response.data));
          });
      }
    });
  }, []);

  const handleAddTrigger = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    setRefreshTriggers(oldValue => !oldValue);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        <Box>
          <HeaderPage
            title="Gatilhos"
            handleAdd={handleAddTrigger}
            disabled={userCantEditTrigger}
          />
          <div className="flex flex-row justify-between gap-2 mb-4">
            <TextField
              label="Pesquisar"
              name="find"
              margin="dense"
              variant="outlined"
              fullWidth
              value={findName}
              style={{ maxWidth: '300px' }}
              onChange={value => {
                setFindName(value.target.value);
              }}
            />
            <Autocomplete
              id="area"
              fullWidth
              getOptionLabel={area => area.name || ''}
              getOptionSelected={(area, value) => area.id === value.id}
              options={areas}
              onChange={(e, value) => {
                setSelectedArea(value || null);
              }}
              value={selectedArea || null}
              disableCloseOnSelect={false}
              loadingText="Carregando"
              renderInput={rest => (
                <TextField
                  {...rest}
                  id="area"
                  label="Área"
                  margin="dense"
                  name="area"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...rest.InputProps,
                    endAdornment: (
                      <>
                        {loadingTriggers ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {rest.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <Autocomplete
              id="phase"
              fullWidth
              getOptionLabel={phase => phase.name || ''}
              getOptionSelected={(phase, value) => phase.id === value.id}
              options={phases}
              onChange={(e, value) => {
                setSelectedPhase(value || null);
              }}
              value={selectedPhase || null}
              disableCloseOnSelect={false}
              loadingText="Carregando"
              renderInput={rest => (
                <TextField
                  {...rest}
                  id="phase"
                  label="Fase"
                  margin="dense"
                  name="phase"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...rest.InputProps,
                    endAdornment: (
                      <>
                        {loadingTriggers ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {rest.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
        </Box>
        <Box className={classes.boxTable}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="lista de gatilhos">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnActions} />
                  <TableCell align="left">Gatilho</TableCell>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="left">Área</TableCell>
                  <TableCell align="left">Fase</TableCell>
                  <TableCell align="center">Ativo</TableCell>
                  <TableCell className={classes.columnActions} />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredTriggers.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : filteredTriggers
                ).map(trigger => (
                  <TableRow key={trigger.id}>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Editar"
                        size="small"
                        onClick={() => handleEdit(trigger.id)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {trigger.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {trigger?.type === 'instant' ? 'Instantâneo' : 'Agendado'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {trigger?.area?.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {trigger?.phase?.name}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ maxWidth: '20px' }}
                    >
                      {trigger.isActive ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Deletar"
                        size="small"
                        onClick={() => handleDelete(trigger.id)}
                        disabled={userCantEditTrigger}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[{ label: 'Todos', value: -1 }, 10, 15]}
                    colSpan={7}
                    count={filteredTriggers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Linhas por página"
                    SelectProps={{
                      inputProps: { 'aria-label': 'Linhas por Página' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {openModal && (
        <ModalWithTitle
          open
          closeModal={handleCloseModal}
          title="Adicionar Gatilho"
          titleColor="#000000"
        >
          <Trigger closeModal={handleCloseModal} />
        </ModalWithTitle>
      )}
    </Container>
  );
};
