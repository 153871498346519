import React from 'react';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radiogroup';
import { Textarea } from 'src/components/ui/textarea';
import { PlusCircleIcon } from 'lucide-react';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { Card } from 'src/components/ui/card';
import { ProfilePermissionTable } from 'src/components/ProfilePermissionTable';
import { useHistory } from 'react-router-dom';
import { Switch } from 'src/components/ui/switch';
import { useProfile } from './hooks/useProfile';
import { ProfilesList } from './components/ProfilesList';

export const Profile: React.FC = () => {
  const {
    formik,
    handleEditProfile,
    loadingPermissions,
    permissionProperties,
    permissionsByCategories,
    handleChangePermission,
    profilesList,
    loadingProfiles,
    handleDeleteProfile,
    userCantEditProfile,
  } = useProfile();
  const history = useHistory();

  return (
    <div className="w-full h-full px-6 overflow-auto">
      <div className="space-y-1 my-6">
        <div className="flex flex-row gap-2 items-center rounded-md mb-2 box-border">
          <h1 className="text-2xl font-bold leading-none mr-2">
            {' '}
            Perfil de acesso
          </h1>
          <Button
            onClick={() => history.goBack()}
            disabled={formik.isSubmitting}
            className="flex gap-1 items-center w-fit h-fit p-2 shadow-sm hover:shadow-sm min-w-20"
            variant="secondary"
          >
            Voltar
          </Button>
          <TooltipWrapper
            value="Novo Perfil"
            side="right"
            className="text-sm font-medium"
          >
            <Button
              onClick={() => handleEditProfile(null)}
              disabled={userCantEditProfile || formik.isSubmitting}
              className="flex gap-1 items-center w-fit h-fit p-2 shadow-lg hover:shadow-md min-w-20"
            >
              <PlusCircleIcon size={16} />
              Criar
            </Button>
          </TooltipWrapper>
        </div>
        <p className="text-sm text-muted-foreground">
          Nessa tela você pode criar e editar perfis de acesso
        </p>
      </div>
      <Card className="pt-4 pb-8 max-w-7xl mb-8">
        <ProfilesList
          handleEditProfile={handleEditProfile}
          profilesList={profilesList}
          loadingProfiles={loadingProfiles}
          handleDeleteProfile={handleDeleteProfile}
          userCantEditProfile={userCantEditProfile}
        />
        <form noValidate className="w-full box-border">
          <div
            className={`flex flex-row ${
              formik.touched.name && formik.errors.name
                ? 'items-center'
                : 'items-end'
            } flex-nowrap justify-between my-4  w-full`}
          >
            <div className="grid w-full">
              <Label htmlFor="name" className="mb-2.5">
                Nome de exibição
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formik.values.name || ''}
                onChange={formik.handleChange}
                placeholder="Digite o nome de exibição"
                className="max-w-lg"
                autoComplete="off"
                required
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-600 text-sm mt-1">
                  {formik.errors.name}
                </div>
              ) : null}
            </div>

            <Button
              className="rounded-md p-2 min-w-24 min-h-fit "
              type="button"
              onClick={() => formik.handleSubmit()}
              disabled={userCantEditProfile || formik.isSubmitting}
            >
              {formik.values?.id ? 'Salvar' : 'Cadastrar'}
            </Button>
          </div>

          <div className="flex items-center gap-2 mb-4">
            <Switch
              id="isProfileActive"
              name="isActive"
              checked={formik.values.isActive}
              onCheckedChange={checked => {
                formik.setFieldValue('isActive', checked);
              }}
            />
            <Label htmlFor="isProfileActive">
              {formik.values.isActive ? 'Ativo' : 'Inativo'}
            </Label>
          </div>
          <div className="flex flex-row gap-2">
            <div>
              <Label htmlFor="type-radio-group" className="mb-1.5">
                Perfil
              </Label>
              <div className="flex flex-col gap-4 border p-2 rounded-sm w-fit h-fit mb-4">
                <RadioGroup
                  id="type-radio-group"
                  name="categoryName"
                  value={formik.values.categoryName}
                  onValueChange={value =>
                    formik.setFieldValue('categoryName', value)
                  }
                  required
                  disabled={formik.values?.id !== undefined}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="ADMINISTRATOR" id="r1" />
                    <Label htmlFor="r1">Administrador</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="CONFIGURATOR" id="r2" />
                    <Label htmlFor="r2">Configurador</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="SUPERVISOR" id="r3" />
                    <Label htmlFor="r3">Supervisor</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="USER" id="r4" />
                    <Label htmlFor="r4">Básico</Label>
                  </div>
                </RadioGroup>
              </div>
              {formik.touched.categoryName && formik.errors.categoryName ? (
                <div className="text-red-600 text-sm mt-1">
                  {formik.errors.categoryName}
                </div>
              ) : null}
            </div>
            <div className="mb-4 w-full">
              <Label htmlFor="ia-context-description">Descrição</Label>
              <Textarea
                id="ai-context-description"
                name="description"
                value={formik.values.description || ''}
                onChange={formik.handleChange}
                required
                className="min-h-[106px]"
                placeholder="Descreva as atividades do perfil e papel junto ao processo. Essa informação será utilizada para compor o prompt de integração na análise de visões com IA"
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-red-600 text-sm mt-1">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
          </div>

          <Label htmlFor="profile-permissions" className="mb-1.5 w-full">
            Permissões
          </Label>
          {loadingPermissions ? (
            <div className="w-full">Carregando...</div>
          ) : (
            permissionsByCategories !== null &&
            permissionProperties !== null && (
              <ProfilePermissionTable
                permissionProperties={permissionProperties}
                permissionsByCategories={permissionsByCategories}
                activePermissions={formik.values.activePermissions}
                currentCategory={formik.values.categoryName}
                handleChangePermission={handleChangePermission}
                className="h-96"
              />
            )
          )}
        </form>
      </Card>
    </div>
  );
};
