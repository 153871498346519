import React from 'react';

import { Link } from 'react-router-dom';

import {
  Button,
  createStyles,
  Typography,
  Hidden,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import logo from '../../assets/logo-brasao-bpm-branco.svg';
import { useAuth } from '../../context/AuthContext';

import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface IFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const classes = useStyles();
  const { signIn } = useAuth();
  const bpmVersion = 'Versão 0.4.5';

  const Version = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <span style={{ color: '#676767' }}>{bpmVersion}</span>
      </div>
    );
  };

  const initialValues: IFormData = {
    email: '',
    password: '',
  };

  const formSchema = Yup.object().shape({
    email: Yup.string().email('E-mail inválido').required('Obrigatório'),
    password: Yup.string().required('Obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async values => {
      await signIn({ email: values.email, password: values.password });
    },
  });

  return (
    <Grid container className={classes.root}>
      <Hidden mdDown>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <div className={classes.logo}>
            <img
              src={logo}
              alt="Logo da Empresa Brasão Sistemas"
              style={{
                width: '250px',
                marginRight: '-105px',
                marginTop: '150px',
              }}
            />
          </div>
          <Paper className={classes.form} elevation={3}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Acesse a sua conta
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                id="email"
                label="Seu e-mail"
                name="email"
                autoComplete="email"
                margin="normal"
                fullWidth
                autoFocus
                InputLabelProps={{
                  style: {
                    color: '#007dc6',
                    fontSize: '14px',
                    fontWeight: 500,
                  },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                name="password"
                label="Sua senha"
                type="password"
                id="password"
                autoComplete="current-password"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  style: {
                    color: '#007dc6',
                    fontSize: '14px',
                    fontWeight: 500,
                  },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button
                  className={classes.submit}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={formik.isSubmitting}
                >
                  Entrar
                </Button>
                <Typography
                  variant="h1"
                  component="h2"
                  gutterBottom
                  className={classes.rodaTitle}
                >
                  <Link
                    style={{
                      color: '#007dc6',
                    }}
                    to="/forgotpassword"
                  >
                    Esqueceu a Senha?
                  </Link>
                </Typography>
              </div>
              <Version />
            </form>
          </Paper>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div className={classes.logo}>
            <img
              src={logo}
              alt="Logo da Empresa Brasão Sistemas"
              style={{
                width: '250px',
                marginRight: '-20px',
                marginTop: '153px',
              }}
            />
          </div>
          <Paper className={classes.form} elevation={3}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Acesse a sua conta
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                id="email"
                label="Seu e-mail"
                name="email"
                autoComplete="email"
                margin="normal"
                fullWidth
                autoFocus
                InputLabelProps={{
                  style: {
                    color: '#007dc6',
                    fontSize: '14px',
                    fontWeight: 'bolder',
                  },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                name="password"
                label="Sua senha"
                type="password"
                id="password"
                autoComplete="current-password"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  style: {
                    color: '#007dc6',
                    fontSize: '14px',
                    fontWeight: 'bolder',
                  },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button
                  className={classes.submit}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={formik.isSubmitting}
                >
                  Entrar
                </Button>
                <Typography
                  variant="h1"
                  component="h2"
                  gutterBottom
                  className={classes.rodaTitle}
                >
                  <Link
                    style={{
                      color: '#007dc6',
                    }}
                    to="/forgotpassword"
                  >
                    Esqueceu a Senha?
                  </Link>
                </Typography>
              </div>
              <Version />
            </form>
          </Paper>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default SignIn;
