/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import {
  Button as ButtonMaterialUI,
  Container,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { ICustomField } from 'src/interface/ICardFields';
import { Conditions } from 'src/components/Conditions';
import { ConditionsExpression } from 'src/components/Conditions/Condition.i';
import {
  accountCustomFieldApi,
  IGetAccountCustomFieldApi,
} from 'src/services/accountCustomFieldApi';
import { useAuth } from 'src/context/AuthContext';
import { formatConditionsExpression } from 'src/components/Conditions/utils/formatConditionsExpression';
import api from '../../services/api';
import Button from '../../components/Button';

import {
  IFormData,
  ITrigger,
  ITask,
  ITriggerParams,
  ITriggerProps,
  IArea,
  IPhase,
  ITriggerAction,
  IFile,
} from './Trigger.i';
import { styles } from './styles';
import { Actions } from './Actions';
import { handleFormatTriggerResponseExpression } from './utils/triggerUtil';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const Trigger: React.FC<ITriggerProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  closeModal = value => null,
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const userCantEditTrigger = user.permissions.TRIGGER !== 'READ_AND_WRITE';

  const history = useHistory();
  const location = useLocation();
  const params = useParams<ITriggerParams>();
  const { trigger_id } = params;

  const goBack = () => {
    if (!trigger_id) {
      closeModal(true);
    } else {
      const previousFilters = new URLSearchParams(location.search);
      const queryString = previousFilters.toString();

      if (queryString) {
        history.push(`/listTriggers?${queryString}`);
      } else {
        history.push('/listTriggers');
      }
    }
  };

  const [selectedArea, setSelectedArea] = useState<IArea | null>(null);
  const [areaPhases, setAreaPhases] = useState<IPhase[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<IPhase | null>(null);
  const [loadingArea, setLoadingArea] = useState(false);
  const [loadingPhase, setLoadingPhase] = useState(false);
  const [areas, setAreas] = useState<IArea[]>([] as IArea[]);
  const [customFields, setCustomFields] = useState<ICustomField[]>([]);
  const [cardFields, setCardFields] = useState<ICustomField[]>([]);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [phaseOption, setPhaseOption] = useState<IPhase[]>([]);

  const [trigger, setTrigger] = useState<ITrigger | undefined>(undefined);
  const [triggerActions, setTriggerActions] = useState<ITriggerAction[]>([]);

  const [filesToSubmit, setFilesToSubmit] = useState<IFile[]>([] as IFile[]);

  const [conditionsExpressionInput, setConditionsExpressionInput] =
    useState<ConditionsExpression>([]);
  const [accountCustomFields, setAccountCustomFields] = useState<
    IGetAccountCustomFieldApi[]
  >([]);

  useEffect(() => {
    const getAccountCustomFields = async () => {
      try {
        const response = await accountCustomFieldApi.getCustomFields();
        setAccountCustomFields(response?.data || []);
      } catch (err: unknown) {
        let errorMessage = 'Falha ao buscar campos personalizados';
        if (err instanceof Error) {
          errorMessage += `: ${err.message}`;
        }
        toast.error(`${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
        setAccountCustomFields([]);
      }
    };
    getAccountCustomFields();
  }, []);

  useEffect(() => {
    if (trigger_id?.length > 0)
      api.get(`/triggers/${trigger_id}`).then(response => {
        const actions = response.data?.triggerActions;
        const { conditionsExpression, triggerConditions } = response.data;

        handleFormatTriggerResponseExpression(
          conditionsExpression,
          triggerConditions,
          setConditionsExpressionInput,
        );

        if (actions && actions.length > 0) setTriggerActions(actions);
        setTrigger(response.data);
      });
  }, [trigger_id]);

  useEffect(() => {
    if ((trigger?.area && trigger?.phase) || (selectedArea && selectedPhase)) {
      const area = (trigger?.area as IArea) || (selectedArea as IArea);
      const phase = (trigger?.phase as IPhase) || (selectedPhase as IPhase);
      setPhaseOption([phase]);

      api.get(`/areas/${area.id}/customFields`).then(response => {
        const newCardFields = response.data.filter((field: ICustomField) => {
          return field?.phaseOrder >= 0;
        });
        setCardFields(newCardFields);

        const filterPhase = response.data.filter(
          (field: ICustomField) => phase.order >= field.phase.order,
        );
        setCustomFields(filterPhase);
      });

      api.get(`/cardTasks/phase/${phase.id}`).then(response => {
        const filterTask = response.data;
        setTasks(filterTask);
      });
    }
  }, [trigger, selectedArea, selectedPhase]);

  useEffect(() => {
    (async () => {
      setLoadingArea(true);
      api
        .get('/areas')
        .then(response => setAreas(response.data))
        .finally(() => setLoadingArea(false));
    })();
  }, []);

  useEffect(() => {
    if (selectedArea && selectedArea?.id && !trigger) {
      (async () => {
        setLoadingPhase(true);

        api
          .get(`areas/${selectedArea.id}/phases`)
          .then(response => {
            setAreaPhases(response.data);
            setConditionsExpressionInput([[{ name: '', type: '' }]]);
            setSelectedPhase(null);
          })
          .finally(() => setLoadingPhase(false));
      })();
    }

    if (!selectedArea && trigger && trigger?.area?.id) {
      (async () => {
        setLoadingPhase(true);

        api
          .get(`areas/${trigger?.area?.id}/phases`)
          .then(response => {
            setAreaPhases(response.data);
          })
          .finally(() => setLoadingPhase(false));
      })();
    }
  }, [selectedArea, trigger]);

  useEffect(() => {
    if (selectedPhase) setConditionsExpressionInput([[{ name: '', type: '' }]]);
  }, [selectedPhase]);

  useEffect(() => {
    let active = true;

    if (!loadingArea) {
      return undefined;
    }

    (async () => {
      const response = await api.get('/areas');

      const formattedAreas = (await response.data) as IArea[];

      if (active) {
        setAreas(formattedAreas);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingArea]);

  const initialValues: IFormData = {
    name: trigger ? trigger.name : '',
    isActive: trigger ? trigger.isActive : true,
    isRepeatable: trigger ? trigger.isRepeatable : false,
    area_id: trigger && trigger.area ? trigger.area.id : '',
    phase_id: trigger && trigger.phase ? trigger.phase.id : '',
    type: trigger && trigger.type ? trigger.type : 'instant',
    frequency: trigger && trigger.frequency ? trigger.frequency : 60,
    frequencyUnit: 's',
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    isActive: Yup.boolean(),
    isRepeatable: Yup.boolean(),
    area_id: Yup.string().required('Área é obrigatório.'),
    phase_id: Yup.string().required('Fase é obrigatório'),
    type: Yup.string().required('Tipo é obrigatório'),
    frequency:
      trigger?.type === 'scheduled'
        ? Yup.number().positive().required()
        : Yup.number().nullable().optional(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
      const savingTrigger = toast.loading(`Salvando gatilho '${values.name}'.`);
      document.body.style.cursor = 'progress';

      try {
        setSubmitting(true);
        const formattedActions = triggerActions.map(action => {
          const {
            name,
            type,
            message,
            suspendDuration,
            emailSubject,
            emailTo,
            emailFrom_id,
            phase_id,
            area_id,
            user_id,
            customFields,
            attachments,
            taskData,
            linkedCardFields,
          } = action;

          if (type === 'alert') {
            return {
              name,
              type,
              message,
            };
          }

          if (type === 'create_single_task') {
            return {
              name,
              type,
              user_id:
                user_id === 'userIsLinkedCardOwner' ||
                taskData?.userIsLinkedCardOwner
                  ? undefined
                  : user_id,
              phase_id,
              taskData:
                (taskData && user_id === 'userIsLinkedCardOwner') ||
                taskData?.userIsLinkedCardOwner
                  ? { ...taskData, userIsLinkedCardOwner: true }
                  : { ...taskData, userIsLinkedCardOwner: false },
              linkedCardFields,
            };
          }

          if (type === 'suspend') {
            return {
              name,
              type,
              suspendDuration: Number(suspendDuration),
            };
          }

          if (type === 'finish') {
            return {
              name,
              type,
            };
          }

          if (type === 'user') {
            return {
              name,
              type,
              user_id,
            };
          }

          if (type === 'area' || type === 'subordinate') {
            return {
              name,
              type,
              area_id,
            };
          }

          if (type === 'phase') {
            return {
              name,
              type,
              phase_id,
            };
          }

          if (type === 'email') {
            return {
              name,
              type,
              message,
              emailSubject,
              emailTo,
              emailFrom_id:
                emailFrom_id && emailFrom_id !== 'cardUser'
                  ? emailFrom_id
                  : undefined,
              emailFromVariable:
                emailFrom_id && emailFrom_id === 'cardUser'
                  ? emailFrom_id
                  : undefined,
              customFields,
              attachments:
                attachments && attachments.length > 0 ? attachments : undefined,
            };
          }
          return action;
        });

        let tempName = '';
        if (typeof conditionsExpressionInput[0][0] === 'object') {
          tempName = conditionsExpressionInput[0][0]?.name || '';
        }

        if (
          conditionsExpressionInput.length <= 0 ||
          conditionsExpressionInput[0].length <= 0 ||
          (conditionsExpressionInput[0].length >= 1 && tempName === '') ||
          formattedActions.length <= 0
        ) {
          toast.update(savingTrigger, {
            render: 'É necessário ao menos uma condição e uma ação.',
            type: 'error',
            isLoading: false,
            autoClose: 5000,
            theme: 'colored',
            position: toast.POSITION.TOP_RIGHT,
          });
          document.body.style.cursor = 'auto';
          setSubmitting(false);
          return;
        }

        if (formattedActions.length > 3) {
          toast.update(savingTrigger, {
            render: 'Número de ações maior que o permitido(3).',
            type: 'error',
            isLoading: false,
            autoClose: 5000,
            theme: 'colored',
            position: toast.POSITION.TOP_RIGHT,
          });
          document.body.style.cursor = 'auto';
          setSubmitting(false);
          return;
        }

        const formattedConditions = formatConditionsExpression({
          conditionsExpressionInput,
          customFields,
          accountCustomFields,
        });

        formData.append('name', values.name);
        formData.append('isActive', values.isActive.toString());
        formData.append('isRepeatable', values.isRepeatable.toString());
        formData.append(
          'conditionsExpression',
          JSON.stringify(formattedConditions),
        );
        formData.append('triggerActions', JSON.stringify(formattedActions));

        if (!trigger || !trigger.id)
          formData.append('type', values.type.toString());

        if (values.type === 'scheduled') {
          let frequencyInSecond = 0;
          switch (values.frequencyUnit) {
            case 'min':
              frequencyInSecond = values.frequency * 60;
              break;
            case 'hr':
              frequencyInSecond = values.frequency * 60 * 60;
              break;
            default:
              frequencyInSecond = values.frequency;
              break;
          }

          if (frequencyInSecond < 60) {
            formik.setFieldError(
              'frequency',
              'Frequência mínima obrigatória de 60 segundos.',
            );
            formik.setFieldError('frequencyUnit', '.');
            toast.update(savingTrigger, {
              render:
                'A frequência do gatilho precisa ser de no mínimo 60 segundos.',
              type: 'error',
              isLoading: false,
              autoClose: 5000,
              theme: 'colored',
              position: toast.POSITION.TOP_RIGHT,
            });
            document.body.style.cursor = 'auto';
            return;
          }

          formData.append('frequency', frequencyInSecond.toString());
        }

        if (filesToSubmit.length > 0) {
          filesToSubmit.forEach(action => {
            action.files.forEach(file => {
              formData.append(
                'files',
                file,
                `${action.actionName}__${file.name}`,
              );
            });
          });
        }
        if (trigger_id) {
          // eslint-disable-next-line no-restricted-syntax, prefer-const
          await api
            .put(`/triggers/${trigger_id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(response => {
              const { warning } = response.data;

              if (response && warning && warning.length > 0) {
                toast.warning(warning, {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'dark',
                  autoClose: 4000,
                });
              }

              toast.update(savingTrigger, {
                render: `Gatilho '${values.name}' editado com sucesso.`,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
                theme: 'colored',
                position: toast.POSITION.TOP_RIGHT,
              });
              document.body.style.cursor = 'auto';
            });
        } else {
          formData.append('area_id', values.area_id);
          formData.append('phase_id', values.phase_id);

          await api
            .post('/triggers', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(response => {
              const { warning } = response.data;

              if (response && warning && warning.length > 0) {
                toast.warning(warning, {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'dark',
                  autoClose: 4000,
                });
              }

              toast.update(savingTrigger, {
                render: `Gatilho '${values.name}' criado com sucesso.`,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
                theme: 'colored',
                position: toast.POSITION.TOP_RIGHT,
              });
              document.body.style.cursor = 'auto';
              setFilesToSubmit([]);
              closeModal(true);
            });
        }
      } catch (error: any) {
        setSubmitting(false);

        toast.update(savingTrigger, {
          render: `Ocorreu um erro ao salvar o gatilho. ${error.response.data.message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
        document.body.style.cursor = 'auto';
      }
    },
  });

  useEffect(() => {
    if (
      formik.values.isRepeatable === true &&
      triggerActions.find(ta => ta.type !== 'alert' && ta.type !== 'email')
    ) {
      formik.setFieldValue('isRepeatable', false);

      setTimeout(() => {
        toast.info(
          'Opção "Repetível para o mesmo card?" foi desabilitada devido as ações cadastradas',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
      }, 1000);
    }
    window.clearTimeout;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerActions]);

  useEffect(() => {
    if ((trigger?.type || formik.values.type) && !trigger?.id) {
      const selectedType = trigger?.type || formik.values.type;
      const hasAlertAction = !!triggerActions.find(
        action => action.type === 'alert',
      );

      setConditionsExpressionInput([[{ name: '', type: '' }]]);

      if (selectedType === 'scheduled' && hasAlertAction) {
        const tempActions = [...triggerActions].filter(
          ta => ta.type !== 'alert',
        );
        setTriggerActions(tempActions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, formik.values.type]);

  return (
    <Container maxWidth={false} className={classes.content}>
      <Box
        className={
          trigger_id
            ? classes.editableTriggerContainer
            : classes.newTriggerContainer
        }
      >
        {trigger_id ? (
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Editar Gatilho
          </Typography>
        ) : (
          <div />
        )}
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box
            className={
              trigger_id
                ? classes.editableTriggerFields
                : classes.newTriggerModalFields
            }
            display="flex"
            flexDirection="column"
            alignItems="start"
            component={trigger_id ? Paper : undefined}
          >
            <Box className={classes.defaultTriggerData}>
              <Box
                display="flex"
                width="100%"
                flexDirection="column"
                justifyContent="space-between"
              >
                <TextField
                  id="name"
                  label="Identificação"
                  name="name"
                  autoComplete="off"
                  margin="dense"
                  autoFocus
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <Autocomplete
                  id="type"
                  fullWidth
                  getOptionLabel={type =>
                    type === 'instant' ? 'Instantâneo' : 'Agendado'
                  }
                  getOptionSelected={(type, value) => type === value}
                  options={['instant', 'scheduled']}
                  value={formik.values.type}
                  onChange={(e, value) => {
                    formik.setFieldValue('type', value);
                  }}
                  disableClearable
                  disabled={!!trigger_id}
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      id="type"
                      label="Tipo"
                      margin="dense"
                      name="phase"
                      value={formik.values.type}
                      fullWidth
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      helperText={formik.touched.type && formik.errors.type}
                      InputProps={{
                        ...rest.InputProps,
                        endAdornment: (
                          <>
                            {loadingPhase ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {rest.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />

                {formik.values.type === 'scheduled' && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <TextField
                      id="frequency"
                      label="Frequência"
                      name="frequency"
                      autoComplete="off"
                      margin="dense"
                      type="number"
                      inputProps={{
                        step: 60,
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.frequency}
                      error={
                        !!(formik.touched.frequency && formik.errors.frequency)
                      }
                      helperText={
                        formik.touched.frequency && formik.errors.frequency
                      }
                      style={{ flex: 1, marginRight: '2px' }}
                    />
                    <FormControl
                      margin="dense"
                      style={{ flex: 1, minWidth: '50px', maxWidth: '100px' }}
                    >
                      <InputLabel id="frequency-unit-select-label">
                        Unidade
                      </InputLabel>
                      <Select
                        margin="dense"
                        autoWidth
                        labelId="frequency-unit-select-label"
                        name="frequencyUnit"
                        value={formik.values.frequencyUnit}
                        onChange={e => {
                          const { value } = e.target;
                          const oldUnit = formik.values.frequencyUnit;

                          if (oldUnit === value) return;

                          formik.setFieldValue('frequencyUnit', value);
                          const currentFrequency = Number(
                            formik.values.frequency,
                          );
                          let newFrequency = 0;

                          if (value === 'hr' && oldUnit === 's')
                            newFrequency = currentFrequency / 60 / 60;

                          if (
                            (value === 'min' && oldUnit === 's') ||
                            (value === 'hr' && oldUnit === 'min')
                          )
                            newFrequency = currentFrequency / 60;

                          if (
                            (value === 'min' && oldUnit === 'hr') ||
                            (value === 's' && oldUnit === 'min')
                          )
                            newFrequency = currentFrequency * 60;

                          if (value === 's' && oldUnit === 'hr')
                            newFrequency = currentFrequency * 60 * 60;

                          formik.setFieldValue('frequency', newFrequency);
                        }}
                      >
                        <MenuItem value="s">Segundos</MenuItem>
                        <MenuItem value="min">Minutos</MenuItem>
                        <MenuItem value="hr">Horas</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isActive"
                      checked={formik.values.isActive}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Ativo?"
                />
                <Tooltip
                  title="
                  Ao marcar esta opção, as ações serão executadas mais de uma vez. 
                  Disponível quando ações são exclusivamente do tipo Alerta e E-mail."
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isRepeatable"
                        checked={formik.values.isRepeatable}
                        onChange={event => {
                          formik.setFieldValue(
                            'isRepeatable',
                            event.target.checked,
                          );
                        }}
                      />
                    }
                    disabled={
                      !!triggerActions.find(
                        ta => ta.type !== 'alert' && ta.type !== 'email',
                      )
                    }
                    label="Repetível para o mesmo card?"
                  />
                </Tooltip>
              </Box>
              <Box display="flex" width="100%" flexDirection="column">
                <Autocomplete
                  id="area"
                  fullWidth
                  getOptionLabel={area => area.name || ''}
                  getOptionSelected={(area, value) => area.id === value.id}
                  options={areas}
                  value={trigger?.area || selectedArea || null}
                  onChange={(e, value) => {
                    setSelectedArea(value || null);
                    formik.setFieldValue('area_id', value?.id || '');
                  }}
                  disableCloseOnSelect={false}
                  disabled={!!trigger_id}
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      id="area"
                      label="Área"
                      margin="dense"
                      name="area"
                      value={formik.values.area_id}
                      fullWidth
                      error={
                        formik.touched.area_id && Boolean(formik.errors.area_id)
                      }
                      helperText={
                        formik.touched.area_id && formik.errors.area_id
                      }
                      InputProps={{
                        ...rest.InputProps,
                        endAdornment: (
                          <>
                            {loadingArea ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {rest.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="phase"
                  fullWidth
                  getOptionLabel={phase => phase.name || ''}
                  getOptionSelected={(phase, value) => phase.id === value.id}
                  options={areaPhases}
                  value={trigger?.phase || selectedPhase || null}
                  onChange={(e, value) => {
                    setSelectedPhase(value || null);
                    formik.setFieldValue('phase_id', value?.id || '');
                  }}
                  disableCloseOnSelect={false}
                  disabled={!!trigger_id}
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      id="phase"
                      label="Fase"
                      margin="dense"
                      name="phase"
                      // variant="outlined"
                      value={formik.values.phase_id}
                      fullWidth
                      error={
                        formik.touched.phase_id &&
                        Boolean(formik.errors.phase_id)
                      }
                      helperText={
                        formik.touched.phase_id && formik.errors.phase_id
                      }
                      InputProps={{
                        ...rest.InputProps,
                        endAdornment: (
                          <>
                            {loadingPhase ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {rest.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            {(trigger?.phase || selectedPhase) &&
              (trigger?.area || selectedArea) && (
                <Conditions
                  conditionsExpressionInput={conditionsExpressionInput}
                  setConditionsExpressionInput={setConditionsExpressionInput}
                  phases={phaseOption}
                  customFields={customFields.filter(
                    field => field.type !== 'TABLE' || !field.tableOrder,
                  )}
                  accountCustomFields={accountCustomFields}
                  tasks={tasks}
                  triggerType={
                    formik.values.type as 'instant' | 'scheduled' | undefined
                  }
                  isTriggerCondition
                />
              )}

            {(trigger?.phase || selectedPhase) &&
              (trigger?.area || selectedArea) && (
                <Actions
                  triggerActions={triggerActions}
                  setTriggerActions={setTriggerActions}
                  areas={areas}
                  phases={areaPhases}
                  currentAreaId={trigger?.area?.id || selectedArea?.id || ''}
                  currentPhaseId={trigger?.phase?.id || selectedPhase?.id || ''}
                  customFields={cardFields}
                  filesToSubmit={filesToSubmit}
                  setFilesToSubmit={setFilesToSubmit}
                  triggerType={formik.values.type}
                />
              )}

            <Box>
              <ButtonMaterialUI
                className={classes.buttonSubmit}
                disabled={formik.isSubmitting}
                size="large"
                variant="contained"
                color="inherit"
                onClick={goBack}
              >
                Voltar
              </ButtonMaterialUI>
              <Button
                className={classes.buttonSubmit}
                disabled={userCantEditTrigger || formik.isSubmitting}
                type="submit"
                color="primary"
                variant="contained"
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
};
