/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import { Switch } from 'react-router-dom';

import { Card } from '@material-ui/core';
import SignDocument from 'src/pages/SignDocument';
import { ListVisions } from 'src/pages/Vision/ListVisions';
import { Profile } from 'src/pages/Profile';
import { DataSource } from 'src/pages/DataSource';
import Route from './Route';

import Area from '../pages/Area';
import Dashboard from '../pages/Dashboard';
import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Business from '../pages/Business';
import Account from '../pages/Account';
import ListArea from '../pages/Area/ListAreasAndPhases';
import ListAccounts from '../pages/Account/List';
import Task from '../pages/Task';
import Contact from '../pages/Contact';
import { CustomField } from '../pages/CustomField';
import ListUser from '../pages/User/components/List';
import Search from '../pages/Search';
import IntegrationRuleList from '../pages/IntegrationRules/list';
import IntegrationRule from '../pages/IntegrationRules';
import EmailsList from '../pages/Email/List';
import Email from '../pages/Email';
import { ListTrigger } from '../pages/Trigger/List';
import { Trigger } from '../pages/Trigger';
import { FormsList } from '../pages/Forms/List';
import { Form } from '../pages/Forms';
import { FillForm } from '../pages/FillForm';
import NotFound from '../pages/_layouts/NotFound';
import { SignatureCompleted } from '../pages/SignatureCompleted';
import { ValidateDocument } from '../pages/ValidateDocument';
import AccountConfiguration from '../pages/Account/AccountConfiguration';
import AccountFieldConfiguration from '../pages/Account/AccountConfiguration/AccountFieldConfiguration';
import Workspace from '../pages/Workspace';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/forgotpassword" exact component={ForgotPassword} />
      <Route path="/resetpassword" exact component={ResetPassword} />

      <Route path="/dashboard" component={Dashboard} exact isPrivate />
      <Route path="/businesses" component={Business} exact isPrivate />
      <Route path="/listusers" component={ListUser} exact isPrivate />

      <Route path="/listareas" component={ListArea} exact isPrivate />
      <Route path="/areas/:area_id" component={Area} exact isPrivate />

      <Route
        path="/phases/:phase_id/tasks/:task_id"
        component={Task}
        exact
        isPrivate
      />

      <Route path="/listaccounts" exact component={ListAccounts} isPrivate />
      <Route path="/accounts/:account_id" exact component={Account} isPrivate />
      <Route
        path="/accounts/:account_id/contacts/:contact_id"
        component={Contact}
        exact
        isPrivate
      />
      <Route
        path="/accountFields"
        exact
        component={AccountConfiguration}
        isPrivate
      />
      <Route
        path="/accountFields/:accountField_id"
        exact
        component={AccountFieldConfiguration}
        isPrivate
      />
      <Route
        path="/phases/:phase_id/customFields/:customField_id"
        component={CustomField}
        exact
        isPrivate
      />

      <Route path="/cards" exact component={Card} isPrivate />
      <Route
        path="/customFields/:custom_field_id"
        component={CustomField}
        exact
        isPrivate
      />

      <Route path="/search" component={Search} exact isPrivate />

      <Route
        path="/listIntegrationsRules"
        component={IntegrationRuleList}
        exact
        isPrivate
      />
      <Route
        path="/integrationsRules/:integrationRule_id"
        component={IntegrationRule}
        exact
        isPrivate
      />

      <Route path="/listEmails" component={EmailsList} exact isPrivate />
      <Route path="/email/:email_id" component={Email} exact isPrivate />

      <Route path="/listTriggers" component={ListTrigger} exact isPrivate />
      <Route path="/triggers/:trigger_id" component={Trigger} exact isPrivate />

      <Route path="/listForms" component={FormsList} exact isPrivate />
      <Route path="/forms/:form_id" component={Form} exact isPrivate />
      <Route
        path="/fillForm/:form_id"
        component={FillForm}
        exact
        isFormOrSignature
      />

      <Route
        path="/sign/:signer_id"
        component={SignDocument}
        exact
        isFormOrSignature
      />

      <Route
        path="/sign/:signer_id/completed"
        component={SignatureCompleted}
        exact
        isFormOrSignature
      />

      <Route
        path="/signatures/validate"
        component={ValidateDocument}
        exact
        isFormOrSignature
      />

      <Route path="/workspace" component={Workspace} exact isPrivate />

      <Route path="/listVisions" component={ListVisions} exact isPrivate />

      <Route path="/profiles" component={Profile} exact isPrivate />
      <Route path="/dataSource" component={DataSource} exact isPrivate />

      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
