/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { fixedAccountFields } from 'src/utils/fixedAccountFields';
import {
  ConditionsExpression,
  ConditionObject,
  TCondition,
  IIntegratedConditionData,
} from 'src/components/Conditions/Condition.i';
import { useCondition } from 'src/components/Conditions/hooks/useCondition';
import { OptionType } from 'src/components/Conditions/ConditionInputs/OptionType';
import { OptionList } from 'src/components/Conditions/ConditionInputs/OptionList';
import { OptionOperator } from 'src/components/Conditions/ConditionInputs/OptionOperator';
import { OptionValue } from 'src/components/Conditions/ConditionInputs/OptionValue';
import { ICRUDCustomField, ICustomField } from 'src/interface/ICardFields';
import { IGetAccountCustomFieldApi } from 'src/services/accountCustomFieldApi';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Trash2Icon } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { ConditionIntegratedFieldModal } from 'src/components/Conditions/ConditionIntegratedFieldModal';
import { extractFieldIntegrationData } from 'src/utils/integratedFieldUtils';

interface IFilterConditionsProps {
  conditionsExpressionInput: ConditionsExpression;
  setConditionsExpressionInput: React.Dispatch<
    React.SetStateAction<ConditionsExpression>
  >;
  customFields: ICustomField[];
  accountCustomFields: IGetAccountCustomFieldApi[];
}

export const FilterConditions: React.FC<IFilterConditionsProps> = ({
  conditionsExpressionInput,
  setConditionsExpressionInput,
  customFields = [],
  accountCustomFields = [],
}) => {
  const [isIntegratedModalOpen, setIsIntegratedModalOpen] = useState(false);
  const [integratedModalData, setIntegratedModalData] =
    useState<IIntegratedConditionData | null>(null);

  const {
    handleFillRuleConditionChange,
    handleAddCondition,
    handleDeleteCondition,
  } = useCondition({
    setConditionsExpressionInput,
    conditionsExpressionInput,
  });

  const handleConditionChange = handleFillRuleConditionChange;

  const handleFillIntegratedField = (value: string) => {
    if (integratedModalData !== null) {
      handleConditionChange(
        'customFieldValue',
        value,
        integratedModalData.groupIndex,
        integratedModalData.itemIndex,
      );
    }
  };

  const handleOpenIntegratedFieldModal = useCallback(
    (cardField: ICRUDCustomField, groupIndex: number, itemIndex: number) => {
      const { dynamicFormat, format, primaryKey } =
        extractFieldIntegrationData(cardField);

      const newModalData = {
        cardField,
        fieldId: cardField.id,
        groupIndex,
        itemIndex,
        format,
        dynamicFormat,
        primaryKeyColumnName: primaryKey,
      };
      setIntegratedModalData(newModalData as any);
      setIsIntegratedModalOpen(true);
    },
    [],
  );

  const handleCloseIntagratedModal = useCallback(() => {
    setIsIntegratedModalOpen(false);
    setIntegratedModalData(null);
  }, []);

  const accountCustomFieldsWithPhase =
    accountCustomFields.length > 0
      ? accountCustomFields.map(field => ({
          ...field,
          phase: { name: 'Personalizado' },
        }))
      : [];

  const accountFixedAndCustomFields = [
    ...accountCustomFieldsWithPhase.sort((a, b) =>
      a.name.localeCompare(b.name),
    ),
    ...fixedAccountFields.sort((a, b) => a.name.localeCompare(b.name)),
  ];

  const conditionsToRender =
    conditionsExpressionInput.length > 0
      ? conditionsExpressionInput
      : [[{ name: '', type: '' }]];

  const renderCondition = (condition: TCondition[], groupIndex: number) => {
    if (condition.length === 0 || !Array.isArray(condition)) {
      return null;
    }

    return condition.map((item: TCondition, itemIndex: number) => {
      if (typeof item !== 'string' && item) {
        const conditionItem = item as ConditionObject;

        const conditionType =
          conditionItem.type ||
          (groupIndex === 0 && itemIndex === 0 ? 'customField' : '');

        const filterSelectedField =
          conditionType === 'customField' &&
          customFields &&
          conditionItem.customField_id
            ? customFields.find(
                field => field.id === conditionItem.customField_id,
              )
            : (conditionType === 'accountField' ||
                conditionType === 'accountFieldFixed') &&
              accountFixedAndCustomFields &&
              (conditionItem.accountField_id || conditionItem.accountFieldName)
            ? accountFixedAndCustomFields.find(
                field =>
                  field.id ===
                  (conditionType === 'accountField'
                    ? conditionItem.accountField_id
                    : conditionItem.accountFieldName),
              )
            : undefined;

        return (
          <TableRow key={`Condition${groupIndex}${itemIndex}`}>
            <TableCell>
              <OptionType
                handleChange={handleConditionChange}
                groupIndex={groupIndex}
                itemIndex={itemIndex}
                itemId={
                  conditionItem.type === 'accountFieldFixed'
                    ? 'accountField'
                    : conditionType
                }
                allowedTypes={[
                  'customField',
                  'accountField',
                  'accountFieldFixed',
                ]}
              />
            </TableCell>
            <TableCell>
              {(conditionType === 'accountField' ||
                conditionType === 'accountFieldFixed') && (
                <OptionList
                  fieldType={
                    conditionType === 'accountField'
                      ? 'accountField'
                      : 'accountFieldFixed'
                  }
                  handleChange={handleConditionChange}
                  optionsArray={accountFixedAndCustomFields || []}
                  groupIndex={groupIndex}
                  itemIndex={itemIndex}
                  itemId={
                    conditionType === 'accountField'
                      ? conditionItem.accountField_id || ''
                      : conditionItem.accountFieldName || ''
                  }
                />
              )}
              {conditionType === 'customField' && (
                <OptionList
                  handleChange={handleConditionChange}
                  optionsArray={customFields || []}
                  groupIndex={groupIndex}
                  itemIndex={itemIndex}
                  itemId={conditionItem.customField_id || ''}
                  fieldType="customField"
                  isCardField
                />
              )}
            </TableCell>
            <TableCell>
              {conditionType && (
                <OptionOperator
                  customFields={
                    conditionType === 'customField'
                      ? customFields || []
                      : conditionType === 'accountField' ||
                        conditionType === 'accountFieldFixed'
                      ? accountFixedAndCustomFields || []
                      : []
                  }
                  item={conditionItem}
                  handleConditionChange={handleConditionChange}
                  groupIndex={groupIndex}
                  itemIndex={itemIndex}
                />
              )}
            </TableCell>
            <TableCell>
              {conditionItem.operator && conditionType && (
                <OptionValue
                  handleChange={handleConditionChange}
                  handleOpenIntegratedFieldModal={
                    handleOpenIntegratedFieldModal
                  }
                  customField={filterSelectedField}
                  groupIndex={groupIndex}
                  itemIndex={itemIndex}
                  fieldValue={conditionItem.value}
                  itemType={conditionType || ''}
                />
              )}
            </TableCell>
            <TableCell className="text-center">
              <Button
                type="button"
                onClick={() => handleDeleteCondition(groupIndex, itemIndex)}
                className="w-fit"
                title="Excluir condição"
                variant="ghost"
              >
                <Trash2Icon
                  className="text-red-600 hover:text-red-700 cursor-pointer"
                  aria-label="Delete area"
                  size={20}
                />
              </Button>
            </TableCell>
          </TableRow>
        );
      }
      return null;
    });
  };

  return (
    <div>
      <div className="mb-4 text-xl font-semibold">Condições</div>
      <div className="overflow-x-auto">
        <Table className="min-w-full border border-gray-200">
          <TableHeader>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Campo</TableCell>
              <TableCell>Operador</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell className="text-center">Ações</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {conditionsToRender.map((condition: any, index: number) => {
              if (Array.isArray(condition)) {
                return (
                  <React.Fragment key={index}>
                    {renderCondition(condition, index)}
                    <TableRow>
                      <TableCell colSpan={5}>
                        <button
                          type="button"
                          onClick={() => handleAddCondition(index)}
                          className="text-blue-500 hover:text-blue-700 text-sm"
                        >
                          + Adicionar Condição
                        </button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </div>

      {isIntegratedModalOpen && integratedModalData && (
        <ConditionIntegratedFieldModal
          openModal={isIntegratedModalOpen}
          handleCloseModal={handleCloseIntagratedModal}
          onSelectedIntegratedItem={handleFillIntegratedField}
          customField_id={integratedModalData.cardField.id}
          customField_name={integratedModalData.cardField.name}
          format={integratedModalData.format}
          dynamicFormat={integratedModalData.dynamicFormat}
          primaryKeyColumnName={integratedModalData.primaryKeyColumnName}
        />
      )}
    </div>
  );
};
