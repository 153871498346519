/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Box, makeStyles, createStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import { zonedTimeToUtc } from 'date-fns-tz';
import { addSeconds, format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  returnTaskTypeName,
  validateTaskCurrentStatus,
} from 'src/utils/taskUtils';
import { convertISODateToStringFormat } from 'src/utils/dateFormatAndComparisonUtils';
import { ModalWithTitle } from 'src/components/ModalWithTitle';
import {
  IAccount,
  ICardData,
  ICardTask,
  IHandleFillCustomFieldParams,
  ISingleTask,
  ITaskArray,
} from 'src/interface/ICardData';
import { IFillRuleDictionary } from 'src/interface/ICardFillRule';
import {
  evaluateFillRulesAsFieldCondition,
  evaluateRuleWithoutFieldCondition,
} from 'src/utils/fillRules/evaluateRules';
import { a11yProps } from 'src/utils/card/cardUtils';
import Main from './Main';
import CardCustomField from './CardCustomField';
import Occurrence from './Occurrence';
import { Hierarchy } from './Hierarchy';
import api from '../../services/api';
import { useTrigger } from '../../context/TriggerContext';
import {
  ICardMainTab,
  IModalCardsProps,
  IOccurrence,
  IRequestData,
  IResponse,
} from './Card.i';
import { TabPanel } from './TabPanel';
import { CardDataContext } from '../../context/CardDataContext';
import { CardTask } from './CardTask';

const useStyles = makeStyles(() =>
  createStyles({
    tabs: {
      zIndex: 999,
    },
    tabPanel: {
      width: '100%',
    },
  }),
);

interface LoadCardDataOptions {
  cardFields?: boolean;
  cardTasks?: boolean;
  singleTasks?: boolean;
}

export const Card: React.FC<IModalCardsProps> = ({
  cardId,
  openModal,
  handleCloseModal,
}) => {
  const classes = useStyles();
  const { updateTriggers } = useTrigger();

  const [value, setValue] = React.useState(0);
  const [cardDescription, setCardDescription] = useState<string>('');
  const [cardCurrentPhase, setCardCurrentPhase] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [cardMainTab, setCardMainTab] = useState<ICardMainTab | null>(null);
  const [cardUser, setCardUser] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [taskList, setTaskList] = useState<ITaskArray>([]);
  const [selectedPhases, setSelectedPhases] = useState<string[]>([]);
  const [phaseList, setPhaseList] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const [occurrences, setOccurrences] = useState<IOccurrence[]>([]);
  const [observation, setObservation] = useState<string>('');
  const [cardCustomFieldPhases, setCardCustomFieldPhases] = useState<
    ICardData[]
  >([]);
  const [fillRuleDictionary, setFillRuleDictionary] =
    useState<IFillRuleDictionary | null>(null);
  const [rulesEvaluatedOnLoad, setRulesEvaluatedOnLoad] =
    useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState(true);
  const [transitioning, setTransitioning] = useState(false);
  const [isSubmittingFields, setIsSubmittingFields] = useState(false);

  const toggleBox = (
    index: number,
    setOpen: boolean,
    type: 'field' | 'task',
  ) => {
    const typeSection =
      type === 'field' ? 'FieldOpenSection' : 'TaskOpenSection';
    const phases = [...cardCustomFieldPhases];
    phases[index][typeSection] = setOpen;
    setCardCustomFieldPhases(phases);
  };

  const handleChangeTab = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const loadMainData = useCallback(() => {
    api
      .get(`/cards/${cardId}`)
      .then(response => {
        if (response.data) {
          setCardMainTab(response.data);
          setCardCurrentPhase(response.data?.phase);
          setCardDescription(response.data?.description);
          if (response.data?.user) setCardUser(response.data?.user);
        }
      })
      .catch((e: AxiosError) => {
        toast.error(`Falha ao carregar dados da aba principal. ${e?.message}`, {
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [cardId]);

  const loadOccurrences = useCallback(() => {
    api
      .get(`cards/${cardId}/occurrences`)
      .then(response => {
        const occurrencesFormatted = response.data.map(
          (occurrence: { created_at: string }) => {
            const hourCorrect = zonedTimeToUtc(
              parseISO(occurrence.created_at),
              'America/Sao_Paulo',
            );

            return {
              ...occurrence,
              hourFormatted: format(hourCorrect, 'dd/MM/yyyy pp', {
                locale: ptBR,
              }),
            };
          },
        );

        setOccurrences(occurrencesFormatted);
      })
      .catch((e: AxiosError) => {
        toast.error(
          `Falha ao carregar dados da aba ocorrências. ${e?.message}`,
          {
            autoClose: 5000,
            theme: 'colored',
            position: toast.POSITION.TOP_RIGHT,
          },
        );
      });
  }, [cardId]);

  useEffect(() => {
    loadMainData();
    loadOccurrences();
  }, []);

  useEffect(() => {
    if (
      taskList.length > 0 &&
      cardCurrentPhase?.id &&
      selectedPhases.length === 0
    ) {
      setSelectedPhases([cardCurrentPhase.id]);
    }
  }, [cardCurrentPhase, taskList]);

  const handleFillCustomField = useCallback(
    ({
      phaseIndex,
      customFieldIndex,
      value,
      valueJSON,
      customFieldId,
      hideField = false,
      predefinedActiveOptions,
      blockField = false,
      newTableColumns,
    }: IHandleFillCustomFieldParams) => {
      const isHidden = hideField || false;
      const isDisabled = blockField || false;

      if (phaseIndex < 0 || customFieldIndex < 0) return;

      setCardCustomFieldPhases(prevCardCustomFieldPhases => {
        const updatedPhases = [...prevCardCustomFieldPhases];

        updatedPhases[phaseIndex].customFields[customFieldIndex].hasChanged =
          true;
        updatedPhases[phaseIndex].customFields[customFieldIndex].isHidden =
          isHidden;
        updatedPhases[phaseIndex].customFields[customFieldIndex].isDisabled =
          isDisabled;

        if (typeof value !== 'undefined') {
          updatedPhases[phaseIndex].customFields[customFieldIndex].value =
            value;
        } else {
          updatedPhases[phaseIndex].customFields[customFieldIndex].valueJSON =
            valueJSON;
        }

        if (predefinedActiveOptions && predefinedActiveOptions.length > 0) {
          updatedPhases[phaseIndex].customFields[
            customFieldIndex
          ].predefinedActiveOptions = predefinedActiveOptions;
        }

        if (
          newTableColumns &&
          updatedPhases[phaseIndex].customFields[customFieldIndex]?.tableColumns
        ) {
          updatedPhases[phaseIndex].customFields[
            customFieldIndex
          ].tableColumns = newTableColumns;
        }

        if (
          customFieldId &&
          fillRuleDictionary &&
          cardMainTab?.account !== undefined
        ) {
          evaluateFillRulesAsFieldCondition(
            customFieldId,
            updatedPhases,
            cardMainTab.account,
            fillRuleDictionary,
            handleFillCustomField,
            cardCurrentPhase?.id || '',
          );
        }

        return updatedPhases;
      });
    },
    [cardMainTab?.account, cardCurrentPhase, fillRuleDictionary],
  );

  const handleToggleTaskPhaseFilter = (phase_id: string) => {
    setSelectedPhases(oldValue => {
      const index = oldValue.indexOf(phase_id);
      const newArray = [...oldValue];

      if (index === -1) {
        newArray.push(phase_id);
      } else {
        newArray.splice(index, 1);
      }

      return newArray;
    });
  };

  const loadCardData = useCallback(
    (options?: LoadCardDataOptions) => {
      if (cardId && cardCurrentPhase !== null && cardUser !== null) {
        let url = `cards/${cardId}/data`;

        // Adjust URL based on query parameters
        if (
          options &&
          (options?.cardFields || options?.cardTasks || options?.singleTasks)
        ) {
          const queryParams = [];
          if (options.cardFields) queryParams.push('cardFields=true');
          if (options.cardTasks) queryParams.push('cardTasks=true');
          if (options.singleTasks) queryParams.push('singleTasks=true');
          url += `?${queryParams.join('&')}`;
        }

        api
          .get<IRequestData>(`cards/${cardId}/data`)
          .then(response => {
            const respPhases = response.data.phases;
            let taskArray: ITaskArray = [];
            const respFillRuleDictionary = response.data.fillRuleDictionary;
            const phases = respPhases.map((mPhase: ICardData) => {
              const newPhase = { ...mPhase };
              let dateAux: Date;
              let previousCompletedAt: Date | '';
              newPhase.TaskOpenSection = mPhase.name === cardCurrentPhase?.name;
              newPhase.FieldOpenSection =
                mPhase.name === cardCurrentPhase?.name;

              if (!options || (options && options.cardTasks)) {
                const cardTasksFormatted: ICardTask[] = newPhase.cardTasks.map(
                  cardTask => {
                    const formattedStartsAt = cardTask.startsAt
                      ? convertISODateToStringFormat(cardTask.startsAt)
                      : '';
                    const formattedEndsAt = cardTask.endsAt
                      ? convertISODateToStringFormat(cardTask.endsAt)
                      : '';
                    const formattedType = returnTaskTypeName(cardTask.type);
                    const deadlineInSeconds = cardTask.deadline * 60 * 60;
                    if (!dateAux) {
                      const parsedDate = parseISO(cardTask.enteredPhaseAt);
                      dateAux = addSeconds(parsedDate, deadlineInSeconds);
                    } else if (dateAux && previousCompletedAt) {
                      dateAux = addSeconds(
                        previousCompletedAt,
                        deadlineInSeconds,
                      );
                    } else if (dateAux && !previousCompletedAt) {
                      dateAux = addSeconds(dateAux, deadlineInSeconds);
                    }
                    previousCompletedAt = cardTask.completedAt
                      ? parseISO(cardTask.completedAt.toString())
                      : '';

                    return {
                      ...cardTask,
                      finally: cardTask.completedAt
                        ? convertISODateToStringFormat(cardTask.completedAt)
                        : '',
                      dateDeadlineFormat: format(dateAux, 'dd/MM/yy HH:mm', {
                        locale: ptBR,
                      }),
                      startsAt: formattedStartsAt,
                      endsAt: formattedEndsAt,
                      done: !!cardTask.completedAt || false,
                      origin: 'P',
                      isProcessTask: true,
                      user: cardUser,
                      phase: {
                        id: newPhase.id,
                        name: newPhase.name,
                      },
                      type: formattedType,
                      status: validateTaskCurrentStatus(
                        !!cardTask.completedAt,
                        cardTask.startsAt,
                        cardTask.endsAt,
                      ),
                    };
                  },
                );
                taskArray = [...taskArray, ...cardTasksFormatted];
                newPhase.cardTasks = cardTasksFormatted;
              }

              if (!options || (options && options.singleTasks)) {
                const singleTasksFormatted: ISingleTask[] =
                  newPhase.singleTasks.map(singleTask => {
                    const formattedStartsAt = singleTask.startsAt
                      ? format(
                          parseISO(singleTask.startsAt.toString()),
                          'dd/MM/yy HH:mm',
                          {
                            locale: ptBR,
                          },
                        )
                      : '';
                    const formattedEndsAt = singleTask.endsAt
                      ? format(
                          parseISO(singleTask.endsAt.toString()),
                          'dd/MM/yy HH:mm',
                          {
                            locale: ptBR,
                          },
                        )
                      : '';
                    const formattedType = returnTaskTypeName(singleTask.type);
                    return {
                      ...singleTask,
                      done: !!singleTask.completedAt || false,
                      isProcessTask: false,
                      wasCreatedByTrigger:
                        singleTask?.wasCreatedByTrigger || false,
                      origin: 'A',
                      phase: {
                        id: newPhase.id,
                        name: newPhase.name,
                      },
                      type: formattedType,
                      startsAt: formattedStartsAt,
                      endsAt: formattedEndsAt,
                      status: validateTaskCurrentStatus(
                        !!singleTask.completedAt,
                        singleTask.startsAt,
                        singleTask.endsAt,
                      ),
                    };
                  });
                taskArray = [...taskArray, ...singleTasksFormatted];

                newPhase.singleTasks = singleTasksFormatted;
              }

              if (!options || (options && options.cardFields)) {
                const cardFieldsFormatted = newPhase?.customFields
                  ?.filter(field => field.tableColumns?.length !== 0)
                  ?.map(field => {
                    const fieldObject = { ...field };
                    fieldObject.isHidden = false;
                    fieldObject.isDisabled = false;
                    fieldObject.hasChanged = false;
                    fieldObject.predefinedActiveOptions =
                      field.predefinedValues ?? [];
                    if (
                      fieldObject.type === 'TABLE' &&
                      fieldObject?.tableColumns &&
                      fieldObject.tableColumns.length > 0
                    ) {
                      fieldObject.tableColumns = fieldObject?.tableColumns.map(
                        tc => {
                          return {
                            ...tc,
                            isHidden: false,
                            predefinedActiveOptions: tc.predefinedValues ?? [],
                            isDisabled: false,
                            hasChanged: false,
                          };
                        },
                      );
                    }

                    return fieldObject;
                  });

                const sortedFields = cardFieldsFormatted.sort(
                  (a, b) => a.phaseOrder - b.phaseOrder,
                );

                newPhase.customFields = sortedFields;
              }

              return newPhase;
            });

            setPhaseList(phases.map(p => ({ id: p.id, name: p.name })));
            setTaskList(taskArray);
            setFillRuleDictionary(respFillRuleDictionary);

            if (options && cardCustomFieldPhases.length > 0) {
              const oldFieldsValue = cardCustomFieldPhases.map(newPhase => {
                const phaseFields =
                  options?.cardFields === undefined
                    ? cardCustomFieldPhases.find(
                        oldPhase => oldPhase.id === newPhase.id,
                      )?.customFields || []
                    : newPhase.customFields || [];

                return {
                  ...newPhase,
                  customFields: phaseFields,
                };
              });
              setCardCustomFieldPhases(oldFieldsValue);
            } else {
              setCardCustomFieldPhases(phases);
            }
          })
          .then(() => {
            setRulesEvaluatedOnLoad(false);
          })
          .catch((e: AxiosError) => {
            let errorMessage = `Falha ao carregar dados do card.`;
            if (axios.isAxiosError(e) && e.response?.data) {
              const errorData = e.response.data as { message?: string };
              errorMessage += ` ${errorData.message || e.message || ''}`;
            } else {
              errorMessage += ` ${e.message || ''}`;
            }
            setRulesEvaluatedOnLoad(true);
            setShowLoadingMessage(false);
            toast.error(errorMessage, {
              autoClose: 5000,
              theme: 'colored',
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    },
    [cardId, cardCurrentPhase, cardUser],
  );

  const handleRefreshTaskList = () => {
    loadCardData({
      cardTasks: true,
      singleTasks: true,
    });
  };

  useEffect(() => {
    loadCardData();
  }, [loadCardData]);

  useEffect(() => {
    if (
      !rulesEvaluatedOnLoad &&
      cardCustomFieldPhases?.length > 0 &&
      fillRuleDictionary !== null &&
      cardCurrentPhase !== null &&
      cardMainTab !== null &&
      cardMainTab?.account !== null &&
      cardMainTab?.account !== undefined
    ) {
      let tempCardFields: ICardData[] = [...cardCustomFieldPhases];
      tempCardFields.forEach((phase, phaseIndex) => {
        phase.customFields.forEach((field, fieldIndex) => {
          const hasFillRules = fillRuleDictionary?.[field.id]?.rules;
          tempCardFields[phaseIndex].customFields[fieldIndex].isHidden = false;
          tempCardFields[phaseIndex].customFields[fieldIndex].isDisabled =
            false;

          if (
            tempCardFields[phaseIndex].customFields[fieldIndex].type ===
            'PREDEFINED_STRINGS'
          ) {
            tempCardFields[phaseIndex].customFields[
              fieldIndex
            ].predefinedActiveOptions =
              tempCardFields[phaseIndex].customFields[fieldIndex]
                .predefinedValues || [];
          }

          if (hasFillRules) {
            tempCardFields =
              evaluateRuleWithoutFieldCondition(
                field.id,
                tempCardFields,
                cardMainTab.account,
                fillRuleDictionary,
                cardCurrentPhase.id,
              )?.cardFields || [];
          }
          const { customFields } = tempCardFields[phaseIndex];

          if (field.type === 'TABLE' && field.tableColumns) {
            customFields[fieldIndex].hasChanged = true;
            const { tableColumns } = customFields[fieldIndex];

            // evaluate every field that belongs to this table columns
            field.tableColumns.forEach((fieldColumn, columnIndex) => {
              const columnRules = fillRuleDictionary?.[fieldColumn.id]?.rules;

              if (columnRules && tableColumns && tableColumns[columnIndex]) {
                tableColumns[columnIndex].isHidden = false;
                tableColumns[columnIndex].isDisabled = false;
                tableColumns[columnIndex].predefinedActiveOptions =
                  tableColumns[columnIndex].predefinedValues || [];

                const { newTableValues } = evaluateRuleWithoutFieldCondition(
                  fieldColumn.id,
                  tempCardFields,
                  cardMainTab.account,
                  fillRuleDictionary,
                  cardCurrentPhase.id,
                  field.id,
                );
                if (newTableValues?.tableColumns) {
                  customFields[fieldIndex].tableColumns =
                    newTableValues?.tableColumns;
                }
                if (newTableValues?.valueJSON) {
                  customFields[fieldIndex].valueJSON =
                    newTableValues?.valueJSON;
                }
              }
            });
          }

          if (field.type !== 'TABLE')
            evaluateFillRulesAsFieldCondition(
              field.id,
              tempCardFields,
              cardMainTab.account,
              fillRuleDictionary,
              handleFillCustomField,
              cardCurrentPhase.id,
            );
        });
      });

      setCardCustomFieldPhases(tempCardFields);
      setRulesEvaluatedOnLoad(true);
      setTimeout(() => {
        setTransitioning(true);
        setShowLoadingMessage(false);
      }, 500);
    }
  }, [
    cardCustomFieldPhases,
    fillRuleDictionary,
    rulesEvaluatedOnLoad,
    cardMainTab?.account,
  ]);

  const handleSaveMainTab = async () => {
    if (cardMainTab)
      try {
        await api.put(`cards/${cardId}`, {
          description:
            cardMainTab.description !== cardDescription
              ? cardMainTab.description
              : undefined,
          isPinned: cardMainTab.isPinned,
          isHighlighted: cardMainTab.isHighlighted,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
  };

  const handleSubmitCard = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>,
    handleGetPdfDocument?: () => void | undefined,
  ) => {
    e.preventDefault();
    const cardFieldValues: IResponse[] = [];
    const cardTasks: IResponse[] = [];

    handleSaveMainTab();

    cardCustomFieldPhases.forEach(cardCustomField => {
      cardCustomField.customFields.forEach(customField => {
        if (customField.hasChanged || customField.type === 'DOCUMENT') {
          if (
            !customField.valueJSON &&
            customField.type !== 'TABLE' &&
            customField.type !== 'PREDEFINED_STRINGS' &&
            customField.type !== 'INTEGRATED' &&
            customField.type !== 'FILE'
          ) {
            cardFieldValues.push({
              id: customField.id,
              value: customField.value,
              isHidden: customField.isHidden,
            });
          } else if (
            !customField.valueJSON &&
            (customField.type === 'TABLE' ||
              customField.type === 'PREDEFINED_STRINGS' ||
              customField.type === 'FILE')
          ) {
            cardFieldValues.push({
              id: customField.id,
              valueJSON: [],
              isHidden: customField.isHidden,
            });
          } else if (
            !customField.valueJSON &&
            customField.type === 'INTEGRATED'
          ) {
            cardFieldValues.push({
              id: customField.id,
              valueJSON: {},
              isHidden: customField.isHidden,
            });
          } else {
            cardFieldValues.push({
              id: customField.id,
              valueJSON: customField.valueJSON,
              isHidden: customField.isHidden,
            });
          }
        }
      });
    });

    const savingCardCustomFields = toast.loading(
      `Salvando informações alterações no card. Aguarde.`,
    );
    document.body.style.cursor = 'progress';

    api
      .put(`cards/${cardId}/data`, {
        cardFieldValues,
        cardTasks,
      })
      .then(response => {
        const card = response?.data?.card;
        const triggers = response?.data?.triggers;
        const id = card?.id;
        const key_card = card?.key_card;
        const accountName = card?.account.name;
        const triggerDateTime = new Date();

        if (id && key_card && triggers && Object.keys(triggers).length > 0) {
          updateTriggers({
            card_id: id,
            key_card,
            triggers,
            accountName,
            triggerDateTime,
          });
        }

        toast.update(savingCardCustomFields, {
          render: 'Card salvo com sucesso',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });

        loadCardData();
      })
      .then(() => {
        if (handleGetPdfDocument !== undefined) handleGetPdfDocument();
      })
      .catch((e: AxiosError) => {
        let responseError: string = '';

        if (
          e?.response?.data &&
          typeof e.response.data === 'object' &&
          'message' in e.response.data
        ) {
          responseError = e.response.data.message as string;
        } else if (e.message) {
          responseError = e.message;
        } else {
          responseError = 'Unknown error';
        }

        toast.update(savingCardCustomFields, {
          render: `Ocorreu um erro. (${responseError})`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        document.body.style.cursor = 'auto';
        setIsSubmittingFields(false);
      });
  };

  const title = cardMainTab?.phase?.name || '';
  const titleLeft = cardMainTab?.account?.name || '';
  const titleRight = cardMainTab?.keyCard || '';
  const titleTopRight = cardMainTab?.user?.name || '';
  const isPinned = cardMainTab?.isPinned || false;
  const isHighlighted = cardMainTab?.isHighlighted || false;

  if (!cardId && cardCurrentPhase === null) return <></>;

  return (
    <ModalWithTitle
      open={openModal}
      closeModal={handleCloseModal}
      title={title}
      titleLeft={titleLeft}
      titleRight={titleRight}
      titleTopRight={titleTopRight}
      isPinned={isPinned}
      isHighlighted={isHighlighted}
    >
      <Tabs
        value={value}
        onChange={handleChangeTab}
        variant="scrollable"
        indicatorColor="primary"
        scrollButtons="auto"
        // fullWidth
        className={classes.tabs}
      >
        <Tab label="Principal" {...a11yProps(0)} />
        <Tab label="Tarefas" {...a11yProps(1)} />
        <Tab label="Dados" {...a11yProps(2)} />
        <Tab label="Ocorrências" {...a11yProps(3)} />
        <Tab label="Hierarquia" {...a11yProps(4)} />
      </Tabs>
      <Box
        display="flex"
        alignItems="start"
        style={{
          border: 'none',
          height: '1px',
          margin: 0,
          flexShrink: 0,
          background: 'rgba(0, 0, 0, 0.12)',
        }}
      >
        <CardDataContext.Provider
          value={{
            handleFillCustomField,
            handleSubmitCard,
            cardCurrentPhase: cardCurrentPhase as { id: string; name: string },
            card_id: cardId,
            cardCustomFieldPhases,
            accountFields: cardMainTab?.account ?? ({} as IAccount),
            fillRuleDictionary,
            showLoadingMessage,
            transitioning,
            rulesEvaluatedOnLoad,
            isSubmittingFields,
            setIsSubmittingFields,
            setCardCustomFieldPhases,
            toggleBox,
            handleSaveMainTab,
          }}
        >
          <TabPanel value={value} index={0} className={classes.tabPanel}>
            {cardMainTab && (
              <Main card={cardMainTab} setCard={setCardMainTab} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tabPanel}>
            <CardTask
              taskList={taskList}
              phaseList={phaseList}
              selectedPhases={selectedPhases}
              handleToggleTaskPhaseFilter={handleToggleTaskPhaseFilter}
              handleRefreshTaskList={handleRefreshTaskList}
              cardData={
                cardMainTab
                  ? {
                      card: {
                        id: cardId,
                        keyCard: cardMainTab?.keyCard as string,
                      },
                      account: {
                        id: cardMainTab.account.id,
                        name: cardMainTab.account.name,
                        contacts: cardMainTab.account?.contacts || [],
                      },
                      contact: cardMainTab.contact,
                      phase: cardMainTab.phase,
                    }
                  : undefined
              }
            />
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.tabPanel}>
            <CardCustomField />
          </TabPanel>
          <TabPanel value={value} index={3} className={classes.tabPanel}>
            {occurrences && (
              <Occurrence
                occurrences={occurrences}
                observation={observation}
                setObservation={setObservation}
                loadOccurrences={loadOccurrences}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={4} className={classes.tabPanel}>
            <Hierarchy card_id={cardId} />
          </TabPanel>
        </CardDataContext.Provider>
        {/* <TabPanel value={value} index={5} className={classes.tabPanel}>
          <Dispatch
            card_id={card}
            account={account}
            closeModal={handleCloseModal}
          />
        </TabPanel> */}
      </Box>
    </ModalWithTitle>
  );
};
