/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';
import api from 'src/services/api';
import { usePermissions } from 'src/hooks/usePermissions';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  PermissionProperties,
  PermissionsByCategories,
  PermissionValues,
  ProfileCategory,
  ProfileType,
} from 'src/services/permissionsApi';
import { useProfilesList } from 'src/hooks/useProfilesList';
import { useAuth } from 'src/context/AuthContext';
import { isNotNullish } from 'src/utils/comparisonUtils';

export interface ProfileFormValues {
  id?: string;
  name: string;
  description: string;
  categoryName: ProfileCategory;
  activePermissions: {
    name: string;
    status: PermissionValues;
  }[];
  isActive?: boolean;
}

const validationSchema = Yup.object({
  id: Yup.string().nullable(),
  name: Yup.string().required('Nome é obrigatório'),
  description: Yup.string().required('Descrição é obrigatória'),
  categoryName: Yup.string().required('Tipo é obrigatório'),
  activePermissions: Yup.array().required(),
  isActive: Yup.boolean().nullable(),
});

interface HookReturn {
  formik: FormikProps<ProfileFormValues>;
  handleEditProfile: (profile: ProfileType | null) => void;
  handleKeyDown: (event: React.KeyboardEvent) => void;
  selectedProfile: ProfileType | null;
  loadingPermissions: boolean;
  permissionProperties: PermissionProperties | null;
  permissionsByCategories: PermissionsByCategories | null;
  handleChangePermission: (
    permissionName: string,
    status: PermissionValues,
  ) => void;
  profilesList: ProfileType[];
  loadingProfiles: boolean;
  setRefreshProfiles: (value: boolean) => void;
  handleDeleteProfile: (profile_id: string) => void;
  userCantEditProfile: boolean;
}

export function useProfile(): HookReturn {
  const { permissionProperties, permissionsByCategories, loadingPermissions } =
    usePermissions();
  const { profilesList, loadingProfiles, setRefreshProfiles } =
    useProfilesList();
  const { user } = useAuth();
  const userCantEditProfile = user.permissions.PROFILE !== 'READ_AND_WRITE';
  const [selectedProfile, setSelectedProfile] = useState<ProfileType | null>(
    null,
  );

  const handleEditProfile = (profile: ProfileType | null) => {
    setSelectedProfile(profile);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const formik = useFormik<ProfileFormValues>({
    initialValues: {
      id: selectedProfile?.id || undefined,
      name: selectedProfile?.name || '',
      description: selectedProfile?.description || '',
      categoryName:
        (selectedProfile?.category.name as ProfileCategory) || 'ADMINISTRATOR',
      activePermissions: selectedProfile?.activePermissions || [],
      isActive:
        selectedProfile?.isActive !== undefined
          ? selectedProfile?.isActive
          : true,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const profileId = selectedProfile?.id || values.id;
      const requestType = profileId ? 'put' : 'post';
      const requestUrl = profileId ? `/profiles/${profileId}` : '/profiles';
      const formattedProfile = {
        ...values,
        id: profileId && 'put' ? undefined : profileId,
        categoryName: profileId && 'put' ? undefined : values.categoryName,
        activePermissions: values.activePermissions.map(permission => ({
          name: permission.name,
          status: permission.status,
        })),
      };

      setSubmitting(true);
      api[requestType](requestUrl, formattedProfile)
        .then(response => {
          const newId = response.data?.profile_id;
          const categoryId = profilesList.find(
            profile => profile.category.name === values.categoryName,
          )?.category.id;
          if (requestType === 'post' && newId && !profileId && categoryId) {
            setSelectedProfile(() => ({
              id: newId,
              name: values.name,
              description: values.description,
              category: {
                id: categoryId,
                name: values.categoryName,
              },
              activePermissions: values.activePermissions,
              isActive: values.isActive,
            }));
            toast.success('Perfil criado com sucesso', {
              theme: 'colored',
              autoClose: 2000,
              closeOnClick: true,
            });
          } else {
            toast.success('Perfil editado com sucesso', {
              theme: 'colored',
            });
          }
          setRefreshProfiles(oldValue => !oldValue);
          // setSelectedProfile(null);
          // resetForm();
        })
        .catch(e => {
          const error = e.response?.data?.message || e?.message;
          const errMessage = `Erro ao salvar perfil. ${error}`;
          toast.error(errMessage, {
            autoClose: 5000,
            theme: 'colored',
            closeOnClick: true,
          });
        })
        .finally(() => setSubmitting(false));
    },
  });

  useEffect(() => {
    if (selectedProfile === null) {
      formik.setValues({
        id: undefined,
        name: '',
        description: '',
        categoryName: 'ADMINISTRATOR',
        activePermissions: [],
        isActive: true,
      });
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (
      isNotNullish(formik.values.categoryName) &&
      isNotNullish(permissionsByCategories) &&
      isNotNullish(permissionProperties)
    ) {
      const { categoryName } = formik.values;
      const permissions = permissionsByCategories[categoryName];
      if (permissions !== undefined && formik.values.id === undefined) {
        const activePermissions = Object.entries(permissions).map(
          ([key, value]) => {
            const { isReadOnly } = permissionProperties[key];
            return {
              name: key,
              status:
                value === 'TOTAL' && !isReadOnly
                  ? 'READ_AND_WRITE'
                  : value === 'TOTAL' && isReadOnly
                  ? 'READ_ONLY'
                  : value,
            };
          },
        );
        formik.setFieldValue('activePermissions', activePermissions);
      }
    }
  }, [
    formik.values.id,
    formik.values.categoryName,
    permissionsByCategories,
    permissionProperties,
  ]);

  useEffect(() => {
    const currentPermissions = formik.values?.activePermissions;
    const categoryName = formik.values?.categoryName;
    const permissions = permissionsByCategories?.[categoryName];
    if (
      formik.values.id &&
      categoryName &&
      currentPermissions.length > 0 &&
      isNotNullish(currentPermissions) &&
      isNotNullish(permissions) &&
      isNotNullish(permissionProperties) &&
      Object.entries(permissions).length !== currentPermissions.length
    ) {
      const newCurrentPermissions: {
        name: string;
        status: PermissionValues;
      }[] = Object.entries(permissions).map(([key, value]) => {
        const activePermission = currentPermissions.find(
          permission => permission.name === key,
        );
        const { isReadOnly } = permissionProperties[key];
        if (activePermission !== undefined) {
          return {
            name: activePermission.name,
            status: activePermission.status,
          };
        }
        return {
          name: key,
          status:
            value === 'TOTAL' && !isReadOnly
              ? 'READ_AND_WRITE'
              : value === 'TOTAL' && isReadOnly
              ? 'READ_ONLY'
              : value,
        };
      });
      formik.setFieldValue('activePermissions', newCurrentPermissions);
    }
  }, [
    formik.values.id,
    formik.values.categoryName,
    formik.values.activePermissions,
    permissionsByCategories,
    permissionProperties,
  ]);

  const handleChangePermission = (
    permissionName: string,
    status: PermissionValues,
  ) => {
    const activePermissions = formik.values.activePermissions.map(
      permission => {
        if (permission.name === permissionName) {
          const newStatus =
            status !== permission.status
              ? status
              : status === 'DISABLED' || permission.status === 'READ_AND_WRITE'
              ? 'READ_ONLY'
              : 'DISABLED';

          return {
            ...permission,
            status: newStatus,
          };
        }
        return permission;
      },
    );
    formik.setFieldValue('activePermissions', activePermissions);
  };

  const handleDeleteProfile = useCallback((profile_id: string) => {
    api
      .delete(`/profiles/${profile_id}`)
      .then(() => {
        setSelectedProfile(null);
        toast.success('Perfil excluído com sucesso.', {
          theme: 'colored',
          closeOnClick: true,
        });
      })
      .catch(e => {
        const error = e.response?.data?.message || e?.message;
        const errMessage = `Erro ao excluir visão. ${error}`;
        toast.error(errMessage, {
          autoClose: 5000,
          theme: 'colored',
          closeOnClick: true,
        });
      })
      .finally(() => {
        setRefreshProfiles(oldValue => !oldValue);
      });
  }, []);

  return {
    formik,
    handleEditProfile,
    handleKeyDown,
    selectedProfile,
    loadingPermissions,
    permissionProperties,
    permissionsByCategories,
    handleChangePermission,
    profilesList,
    loadingProfiles,
    setRefreshProfiles,
    handleDeleteProfile,
    userCantEditProfile,
  } as HookReturn;
}
