import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { TaskTypes } from 'src/utils/taskTypes.constants';
import { AutocompleteField } from 'src/components/TaskInputFields/AutocompleteField';
import { TextInput } from 'src/components/TaskInputFields/TextInput';
import { useFormikErrorContext } from 'src/context/FormikErrorProvider';
import { taskModalStyles } from '../taskModalStyles';
import { TaskDetailsDataProps } from '../TaskModal.i';
import { TaskLinkedCardFields } from './TaskLinkedCardFields';

export const TaskDetailsData: React.FC<TaskDetailsDataProps> = ({
  handleChangeTaskFieldValue,
  handleObjectValue,
  task_type,
  done,
  guestUsers,
  fieldValues,
  users,
  editPermissionDisabledForUser,
  linkedCardFieldValues,
  cardFieldsData,
  cardMainTab,
  cardCurrentPhase,
  fillRuleDictionary,
  handleChangeCardFieldValue,
  cardId,
  areaId,
  cardFieldsErrorList,
  cardTableFieldsErrorList,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...rest
}) => {
  const classes = taskModalStyles();
  const { errors, hasError } = useFormikErrorContext();
  const editFieldDisabled = done || editPermissionDisabledForUser;

  if (task_type && !TaskTypes[task_type]) {
    return null;
  }

  const typeFields = task_type ? TaskTypes[task_type].fields : [];
  const typeAssocietedFields = task_type
    ? TaskTypes[task_type]?.associatedFields || []
    : [];

  if (
    typeFields.length === 0 &&
    typeAssocietedFields.length === 0 &&
    linkedCardFieldValues.length === 0
  ) {
    return null;
  }

  return (
    <>
      <Box width="100%" className={classes.groupLabel}>
        Detalhes
      </Box>
      <Box className={classes.groupBoxWithBorder}>
        <Grid container spacing={2}>
          {typeFields &&
            typeFields.length > 0 &&
            typeFields.map(field => (
              <Grid item key={field.name} xs={12} sm={6}>
                <TextInput
                  fieldId={field.id}
                  fieldName={field.id}
                  fieldLabel={field.name}
                  fieldValue={
                    fieldValues[fieldValues.findIndex(f => f?.id === field?.id)]
                      ?.value || ''
                  }
                  margin="none"
                  handleOnBlur={handleChangeTaskFieldValue}
                  isDisabled={editFieldDisabled}
                />
              </Grid>
            ))}
          {typeAssocietedFields &&
            typeAssocietedFields.includes('GUEST_USERS') && (
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  gap: '0.25rem',
                }}
              >
                <AutocompleteField
                  fieldId="guestUsers"
                  fieldName="Usuários Convidados"
                  fieldValue={guestUsers}
                  fieldOptions={users}
                  isDisabled={editFieldDisabled}
                  margin="none"
                  handleChangeValue={handleObjectValue}
                  isMultipleOptions
                  error={hasError('guest_users')}
                  helperText={hasError('guest_users') ? errors.guest_users : ''}
                />
              </Grid>
            )}
          {linkedCardFieldValues && cardId && areaId && (
            <TaskLinkedCardFields
              linkedCardFieldValues={linkedCardFieldValues}
              cardFieldsData={cardFieldsData || []}
              handleChangeCardFieldValue={handleChangeCardFieldValue}
              cardId={cardId || ''}
              areaId={areaId || ''}
              done={done}
              cardFieldsErrorList={cardFieldsErrorList}
              cardTableFieldsErrorList={cardTableFieldsErrorList}
              cardMainTab={cardMainTab}
              cardCurrentPhase={cardCurrentPhase}
              fillRuleDictionary={fillRuleDictionary}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};
