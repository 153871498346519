/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ICardData,
  ICardTask,
  IHandleFillCustomFieldParams,
  ITaskArray,
} from 'src/interface/ICardData';
import { IFillRuleDictionary } from 'src/interface/ICardFillRule';
import { ICardMainTab, IRequestData } from 'src/pages/Card/Card.i';
import api from 'src/services/api';
import {
  evaluateFillRulesAsFieldCondition,
  evaluateRuleWithoutFieldCondition,
} from 'src/utils/fillRules/evaluateRules';
import {
  returnTaskTypeName,
  validateTaskCurrentStatus,
} from 'src/utils/taskUtils';
import { convertISODateToStringFormat } from 'src/utils/dateFormatAndComparisonUtils';
import { deepClone } from 'src/utils/deepCloneObject';
import { findCustomFieldById } from 'src/utils/card/cardUtils';

interface HookReturn {
  cardFieldsData: ICardData[];
  cardMainTab: ICardMainTab | null;
  cardCurrentPhase: {
    id: string;
    name: string;
  } | null;
  rulesEvaluatedOnLoad: boolean;
  fillRuleDictionary: IFillRuleDictionary;
  handleFillCustomField: (params: IHandleFillCustomFieldParams) => void;
}

interface TaskFillRuleProps {
  card_id?: string;
  shouldEvaluateFillRules?: boolean;
  taskCompleted?: boolean;
}

export function useTaskFillRule({
  card_id,
  shouldEvaluateFillRules = false,
  taskCompleted = false,
}: TaskFillRuleProps): HookReturn {
  const [cardMainTab, setCardMainTab] = useState<ICardMainTab | null>(null);
  const [cardCurrentPhase, setCardCurrentPhase] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [cardFieldsData, setCardFieldsData] = useState<ICardData[]>([]);
  const [fillRuleDictionary, setFillRuleDictionary] =
    useState<IFillRuleDictionary | null>(null);
  const [
    rulesWithoutConditionEvaluatedOnLoad,
    setRulesWithoutConditionEvaluatedOnLoad,
  ] = useState<boolean>(false);
  const [rulesEvaluatedOnLoad, setRulesEvaluatedOnLoad] =
    useState<boolean>(false);

  const loadCardMainData = useCallback(() => {
    if (!card_id) return;

    api
      .get(`/cards/${card_id}`)
      .then(response => {
        if (response.data) {
          setCardMainTab(response.data);
          setCardCurrentPhase(response.data?.phase);
        }
      })
      .catch((e: any) => {
        const erMsg = e?.response?.data?.message || e?.message;
        toast.error(`Falha ao carregar dados do card vinculado. ${erMsg}`, {
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [card_id]);

  const loadCardFieldsData = useCallback(() => {
    const options = {
      cardFields: true,
      cardTasks: true,
      singleTasks: false,
    };

    if (card_id && cardCurrentPhase !== null && cardMainTab !== null) {
      let url = `cards/${card_id}/data`;
      const queryParams = [];
      if (options.cardFields) queryParams.push('cardFields=true');
      if (options.cardTasks) queryParams.push('cardTasks=true');
      url += `?${queryParams.join('&')}`;

      api
        .get<IRequestData>(url)
        .then(response => {
          const respPhases = response.data.phases;
          let taskArray: ITaskArray = [];
          // const tempCardFields: ICardData[] = [...respPhases?.customFields];
          const respFillRuleDictionary = response.data.fillRuleDictionary;
          const phases = respPhases.map((mPhase: ICardData) => {
            const newPhase = { ...mPhase };

            if (!options || (options && options.cardTasks)) {
              const cardTasksFormatted: ICardTask[] = newPhase.cardTasks.map(
                cardTask => {
                  const formattedType = returnTaskTypeName(cardTask.type);

                  return {
                    ...cardTask,
                    finally: cardTask?.completedAt
                      ? convertISODateToStringFormat(cardTask.completedAt)
                      : '',
                    done: !!cardTask.completedAt || false,
                    origin: 'P',
                    isProcessTask: true,
                    user: cardMainTab.user,
                    phase: {
                      id: newPhase.id,
                      name: newPhase.name,
                    },
                    type: formattedType,
                    status: validateTaskCurrentStatus(
                      !!cardTask.completedAt,
                      cardTask.startsAt,
                      cardTask.endsAt,
                    ),
                  };
                },
              );
              taskArray = [...taskArray, ...cardTasksFormatted];
              newPhase.cardTasks = cardTasksFormatted;
            }

            if (!options || (options && options.cardFields)) {
              const cardFieldsFormatted = newPhase?.customFields
                ?.filter(field => field.tableColumns?.length !== 0)
                ?.map(field => {
                  const fieldObject = { ...field };
                  const hasFillRules =
                    respFillRuleDictionary?.[fieldObject.id]?.rules || [];
                  fieldObject.isHidden = false;
                  fieldObject.isDisabled = false;
                  fieldObject.hasChanged = false;
                  fieldObject.predefinedActiveOptions =
                    field.predefinedValues ?? [];

                  if (
                    fieldObject.type === 'TABLE' &&
                    fieldObject?.tableColumns &&
                    fieldObject.tableColumns.length > 0
                  ) {
                    fieldObject.tableColumns = fieldObject?.tableColumns.map(
                      tc => {
                        return {
                          ...tc,
                          isHidden: false,
                          predefinedActiveOptions: tc.predefinedValues ?? [],
                          isDisabled: false,
                          hasChanged: false,
                        };
                      },
                    );

                    fieldObject.tableColumns.forEach(fieldColumn => {
                      const columnRules =
                        respFillRuleDictionary?.[fieldColumn.id]?.rules;
                      const tableColumn = fieldObject?.tableColumns;

                      if (columnRules && tableColumn) {
                        const { newTableValues } =
                          evaluateRuleWithoutFieldCondition(
                            fieldColumn.id,
                            respPhases,
                            cardMainTab.account,
                            respFillRuleDictionary,
                            cardCurrentPhase.id,
                            fieldObject.id,
                          );

                        if (newTableValues?.tableColumns) {
                          fieldObject.tableColumns =
                            newTableValues.tableColumns;
                        }
                        if (newTableValues?.valueJSON) {
                          fieldObject.valueJSON = newTableValues.valueJSON;
                        }
                      }
                    });
                  }

                  if (hasFillRules) {
                    const { cardFields } = evaluateRuleWithoutFieldCondition(
                      fieldObject.id,
                      respPhases,
                      cardMainTab.account,
                      respFillRuleDictionary,
                      cardCurrentPhase.id,
                    );

                    const findFieldById = findCustomFieldById(
                      cardFields,
                      fieldObject.id,
                    );

                    if (cardFields && findFieldById) {
                      if (findFieldById?.type === 'TABLE') {
                        findFieldById.tableColumns = fieldObject.tableColumns;
                        findFieldById.valueJSON = fieldObject.valueJSON;
                      }
                      return findFieldById;
                    }
                  }

                  return fieldObject;
                });

              const sortedFields = cardFieldsFormatted.sort(
                (a, b) => a.phaseOrder - b.phaseOrder,
              );

              newPhase.customFields = sortedFields;
            }

            return newPhase;
          });

          setFillRuleDictionary(respFillRuleDictionary);
          setCardFieldsData(phases);
          setRulesWithoutConditionEvaluatedOnLoad(true);
        })
        .then(() => {
          setRulesEvaluatedOnLoad(false);
        })
        .catch((e: any) => {
          setRulesEvaluatedOnLoad(true);
          const errorMessage = `Falha ao carregar dados do card.`;
          const erMsg = e?.response?.data?.message || e?.message;
          toast.error(`${errorMessage} ${erMsg}`, {
            autoClose: 5000,
            theme: 'colored',
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }, [card_id, cardMainTab, cardCurrentPhase]);

  useEffect(() => {
    loadCardMainData();
    loadCardFieldsData();
  }, [card_id, shouldEvaluateFillRules]);

  const handleFillCustomField = useCallback(
    ({
      phaseIndex,
      customFieldIndex,
      value,
      valueJSON,
      customFieldId,
      hideField = false,
      predefinedActiveOptions,
      blockField = false,
      newTableColumns,
    }: IHandleFillCustomFieldParams) => {
      const isHidden = hideField || false;
      const isDisabled = blockField || false;

      if (phaseIndex < 0 || customFieldIndex < 0) return;

      setCardFieldsData(prevCardCustomFieldPhases => {
        const updatedPhases = deepClone(prevCardCustomFieldPhases);

        updatedPhases[phaseIndex].customFields[customFieldIndex].hasChanged =
          true;
        updatedPhases[phaseIndex].customFields[customFieldIndex].isHidden =
          isHidden;
        updatedPhases[phaseIndex].customFields[customFieldIndex].isDisabled =
          isDisabled;

        if (valueJSON === undefined) {
          updatedPhases[phaseIndex].customFields[customFieldIndex].value =
            value;
        } else {
          updatedPhases[phaseIndex].customFields[customFieldIndex].valueJSON =
            valueJSON;
        }

        if (predefinedActiveOptions && predefinedActiveOptions.length > 0) {
          updatedPhases[phaseIndex].customFields[
            customFieldIndex
          ].predefinedActiveOptions = predefinedActiveOptions;
        }

        if (
          newTableColumns &&
          updatedPhases[phaseIndex].customFields[customFieldIndex]?.tableColumns
        ) {
          updatedPhases[phaseIndex].customFields[
            customFieldIndex
          ].tableColumns = newTableColumns;
        }

        if (
          customFieldId &&
          fillRuleDictionary &&
          cardMainTab?.account !== undefined &&
          updatedPhases
        ) {
          evaluateFillRulesAsFieldCondition(
            customFieldId,
            updatedPhases,
            cardMainTab.account,
            fillRuleDictionary,
            handleFillCustomField,
            cardCurrentPhase?.id || '',
          );
        }

        return updatedPhases;
      });
    },
    [cardMainTab, cardCurrentPhase, fillRuleDictionary],
  );

  useEffect(() => {
    if (
      rulesWithoutConditionEvaluatedOnLoad &&
      !taskCompleted &&
      !rulesEvaluatedOnLoad &&
      cardFieldsData?.length > 0 &&
      fillRuleDictionary !== null &&
      cardCurrentPhase !== null &&
      cardMainTab !== null &&
      cardMainTab?.account !== null &&
      cardMainTab?.account !== undefined
    ) {
      const tempCardFields: ICardData[] = deepClone(cardFieldsData);

      tempCardFields.forEach(phase => {
        const clonedPhaseFields = deepClone(phase.customFields);
        clonedPhaseFields.forEach(field => {
          if (field.type !== 'TABLE') {
            evaluateFillRulesAsFieldCondition(
              field.id,
              tempCardFields,
              cardMainTab.account,
              fillRuleDictionary,
              handleFillCustomField,
              cardCurrentPhase.id,
            );
          }
        });
      });

      setRulesEvaluatedOnLoad(true);
    }
  }, [
    cardFieldsData,
    fillRuleDictionary,
    rulesEvaluatedOnLoad,
    cardMainTab?.account,
    handleFillCustomField,
    taskCompleted,
    rulesWithoutConditionEvaluatedOnLoad,
  ]);

  return {
    cardFieldsData,
    cardMainTab,
    cardCurrentPhase,
    fillRuleDictionary,
    handleFillCustomField,
    rulesEvaluatedOnLoad,
  } as HookReturn;
}
