import { IntegratedItemFormat } from 'src/components/ModalIntegratedCustomFields';
import { ICRUDCustomField } from 'src/interface/ICardFields';

export type integrationFormat = {
  [key: string]: IntegratedItemFormat;
} | null;

interface DynamicFieldData {
  format: integrationFormat;
  dynamicFormat: integrationFormat;
  primaryKey: string;
}

export const extractFieldIntegrationData = (
  cardField: ICRUDCustomField,
): DynamicFieldData => {
  const hasDynamicFieldAssociations =
    cardField?.integrationRule.integrationType.hasDynamicFieldAssociations;
  const { dynamicFormat } = cardField.integrationRule;
  const format = cardField.integrationRule.integrationType.columnFormats;
  const primaryKey =
    hasDynamicFieldAssociations && dynamicFormat !== null
      ? Object.values(dynamicFormat).find(df => df.isPrimaryKeyColumn)?.name ||
        ''
      : cardField.integrationRule.integrationType.primaryKeyColumnName || '';

  return { dynamicFormat, format, primaryKey };
};

export function getFormatAlignValue(
  columnName: string,
  tableColumns: IntegratedItemFormat[],
  format: integrationFormat,
  hasFormat?: boolean,
): string | undefined {
  let searchColumnName = columnName;
  if (hasFormat && format !== null && format[columnName]) {
    searchColumnName = format[columnName]?.name || columnName;
  }
  const alignValue = tableColumns.find(
    tc => tc.name === searchColumnName,
  )?.align;
  return alignValue;
}
