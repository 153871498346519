import React, { useEffect, useState } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import api from 'src/services/api';
import { toastLoadingUpdate } from 'src/utils/toastifyUtils';
import { toast } from 'react-toastify';
import { useAuth } from 'src/context/AuthContext';
import { validateUserStats } from 'src/utils/userStatusUtils';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { Trash2Icon } from 'lucide-react';
import { Button } from 'src/components/ui/button';

interface InactiveDialogProps {
  open: boolean;
  userId: string;
  name: string;
  email: string;
  previousActiveStatus: boolean;
  previousStartDate: string | null;
  previousEndDate: string | null;
  closeDialog: (statusChanged?: boolean) => void;
}

export const InactiveDialog: React.FC<InactiveDialogProps> = ({
  open,
  userId,
  name,
  email,
  previousActiveStatus,
  previousStartDate,
  previousEndDate,
  closeDialog,
}) => {
  const { user } = useAuth();
  const isSameUser = userId === user.id;
  const [inactivationType, setInactivationType] = useState<string>('Ativo');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const newDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (previousStartDate && previousEndDate) {
      setStartDate(previousStartDate);
      setEndDate(previousEndDate);
      setInactivationType('Período');
    }
  }, [previousStartDate, previousEndDate]);

  useEffect(() => {
    if (previousActiveStatus === false) {
      setInactivationType('Imediato');
    }
  }, [previousActiveStatus]);

  const validateDates = (newStartDate: string, newEndDate: string) => {
    if (newStartDate && newEndDate) {
      if (new Date(newStartDate) > new Date(newEndDate)) {
        setError('A data de início não pode ser posterior à data de fim.');
      } else {
        setError(null);
      }
    }
  };

  const handleStartDateChange = (value: string) => {
    setStartDate(value);
    validateDates(value, endDate);
  };

  const handleEndDateChange = (value: string) => {
    if (startDate) {
      setEndDate(value);
      if (new Date(value) >= new Date(startDate)) {
        setError(null);
      } else {
        setError('A data de fim não pode ser anterior à data de início.');
      }
    }
  };

  const handleClearDates = () => {
    setInactivationType('Ativo');
    setStartDate('');
    setEndDate('');
  };

  const handleSubmit = () => {
    const isActive =
      inactivationType === 'Ativo' || inactivationType === 'Período';
    const inactiveStartsAt = inactivationType === 'Período' ? startDate : null;
    const inactiveEndsAt = inactivationType === 'Período' ? endDate : null;
    setIsSubmiting(true);
    const savingTask = toast.loading(`Inativando Usuário.`, {
      autoClose: 5000,
    });
    document.body.style.cursor = 'progress';

    const successMsg =
      inactivationType === 'Ativo'
        ? 'Usuário ativado com sucesso.'
        : inactivationType === 'Imediato'
        ? 'Usuário inativado com sucesso.'
        : 'Agendamento de inativação realizado com sucesso.';

    api
      .patch(`/users/${userId}/status`, {
        isActive,
        inactiveStartsAt,
        inactiveEndsAt,
      })
      .then(() => {
        toastLoadingUpdate(savingTask, successMsg, 'success', 5000);
        setIsSubmiting(false);
        document.body.style.cursor = 'auto';
        closeDialog(true);
      })
      .catch(() => {
        toastLoadingUpdate(
          savingTask,
          'Falha ao alterar situação do usuário.',
          'success',
          5000,
        );
      })
      .finally(() => {
        setIsSubmiting(false);
        document.body.style.cursor = 'auto';
      });
  };

  const handleConfirmClick = () => {
    if (
      inactivationType === 'Período' &&
      new Date(endDate) < new Date(startDate)
    ) {
      setError('A data de fim deve ser igual ou posterior à data de início.');
      return;
    }

    if (inactivationType === 'Período' && new Date(endDate) < new Date()) {
      setError('A data de fim deve ser superoir à data atual.');
      return;
    }

    if (
      isSameUser &&
      (inactivationType === 'Imediato' ||
        (inactivationType === 'Período' && new Date(startDate) <= new Date()))
    ) {
      setError('Você não pode inativar o próprio usuário na data atual.');
      return;
    }

    setError(null);
    handleSubmit();
  };

  return (
    <AlertDialog open={open}>
      <AlertDialogOverlay className="z-[1500]">
        <AlertDialogContent
          onKeyDown={e => {
            if (open && e.key === 'Escape' && !isSubmiting) closeDialog();
          }}
        >
          <AlertDialogHeader>
            <AlertDialogTitle className="flex flex-row items-center">
              Inativação
            </AlertDialogTitle>
            <AlertDialogDescription>
              Escolha se deseja ativar, inativar de imediato ou agendar a
              inativação.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <div className="w-full space-y-4">
            <div className="flex flex-col gap-2">
              <p>
                <strong>Nome:</strong> {name}
              </p>
              <p>
                <strong>Email:</strong> {email}
              </p>
              <p>
                <strong>Situação atual:</strong>{' '}
                {validateUserStats(
                  previousActiveStatus,
                  previousStartDate,
                  previousEndDate,
                )}
              </p>
            </div>

            <RadioGroup
              value={inactivationType}
              onValueChange={value => {
                setInactivationType(value);
                if (value === 'Ativo' || value === 'Imediato') {
                  setStartDate('');
                  setEndDate('');
                  setError(null);
                }
              }}
              className="flex flex-col"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Ativo" id="ativo" />
                <Label htmlFor="ativo" className="text-sm cursor-pointer">
                  Ativo
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Imediato" id="imediato" />
                <Label htmlFor="imediato" className="text-sm cursor-pointer">
                  Inativo Imediato
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Período" id="periodo" />
                <Label htmlFor="periodo" className="text-sm cursor-pointer">
                  Inativo Período
                </Label>
              </div>
            </RadioGroup>

            <div className="flex flex-row items-center gap-4">
              <div>
                <Label htmlFor="start-date" className="text-sm">
                  Data de Início
                </Label>
                <Input
                  id="start-date"
                  type="date"
                  value={startDate}
                  onChange={e => handleStartDateChange(e.target.value)}
                  disabled={inactivationType !== 'Período'}
                  min={
                    previousStartDate && previousStartDate < newDate
                      ? previousStartDate
                      : newDate
                  }
                />
              </div>
              <div>
                <Label htmlFor="end-date" className="text-sm">
                  Data de Fim
                </Label>
                <Input
                  id="end-date"
                  type="date"
                  value={endDate}
                  onChange={e => handleEndDateChange(e.target.value)}
                  disabled={
                    inactivationType !== 'Período' ||
                    (!startDate && inactivationType === 'Período')
                  }
                  min={startDate || newDate || undefined}
                />
              </div>
              <div className="mt-5 -ml-2">
                <TooltipWrapper
                  value="Excluir datas e Ativar o usuário"
                  align="center"
                  side="top"
                  className="mb-2"
                >
                  <Button
                    type="button"
                    variant="ghost"
                    disabled={inactivationType !== 'Período'}
                    onClick={event => {
                      event.stopPropagation();
                      handleClearDates();
                    }}
                  >
                    <Trash2Icon
                      className="text-red-600 hover:text-red-700 cursor-pointer"
                      aria-label="Clear inativation dates"
                    />
                  </Button>
                </TooltipWrapper>
              </div>
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </div>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => closeDialog()}
              disabled={isSubmiting}
            >
              Cancelar
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleConfirmClick}
              disabled={isSubmiting || error !== null}
            >
              Continuar
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
