/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from 'src/context/AuthContext';

import * as Yup from 'yup';
import api from 'src/services/api';
import { useAreas } from 'src/hooks/useAreas';
import { IArea } from 'src/services/areaApi';
import { useProfilesList } from 'src/hooks/useProfilesList';
import {
  PermissionProperties,
  PermissionsByCategories,
  PermissionValues,
  ProfileType,
} from 'src/services/permissionsApi';
import { usePermissions } from 'src/hooks/usePermissions';
import { isNotNullish } from 'src/utils/comparisonUtils';
import {
  formatDateToISOString,
  formatISOStringToTypeDate,
} from 'src/utils/dateFormatAndComparisonUtils';
import { removeNonNumeric } from 'src/utils/formatStringUtils';
import {
  IBusiness,
  IEmail,
  IFormData,
  IUserCRUD,
  IUserParams,
} from '../User.i';

interface UseUserParams {
  closeModal: (value: boolean) => void;
  userIdProp?: string;
}
interface HookReturn {
  formik: FormikProps<IFormData>;
  user: IUserCRUD | undefined;
  areas: IArea[];
  businesses: IBusiness[];
  loadingBusiness: boolean;
  loadingArea: boolean;
  goBack: () => void;
  filteredEmails: IEmail[];
  handleSelectEmail: (
    email: IEmail,
    checkboxType: string,
    checked: boolean,
  ) => void;
  defaultEmailChecked: boolean;
  emails: IEmail[];
  user_id: string | undefined;
  profilesList: ProfileType[];
  loadingProfiles: boolean;
  editPermissionDisabledForUser: boolean;
  handleChangePermission: (
    permissionName: string,
    status: PermissionValues,
  ) => void;
  loadingPermissions: boolean;
  permissionProperties: PermissionProperties | null;
  permissionsByCategories: PermissionsByCategories | null;
  isSameUser: boolean;
  isUserAdmin: boolean;
}

export function useUser({ closeModal, userIdProp }: UseUserParams): HookReturn {
  const { user: userAuth, signOut } = useAuth();
  const params = useParams<IUserParams>();
  const { user_id } = params;
  const userId = userIdProp || user_id;
  const isUserAdmin = userAuth?.profile.categoryName === 'ADMINISTRATOR';
  const isSameUser = userAuth.id === userId;
  const editPermissionDisabledForUser =
    userAuth?.permissions.USER !== 'READ_AND_WRITE';
  const { areas } = useAreas();
  const { profilesList, loadingProfiles } = useProfilesList();
  const { permissionProperties, permissionsByCategories, loadingPermissions } =
    usePermissions();

  const [user, setUser] = useState<IUserCRUD>();
  const [businesses, setBusinesses] = useState<IBusiness[]>([] as IBusiness[]);
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [userEmails, setUserEmails] = useState<IEmail[]>([]);
  const [filteredEmails, setFilteredEmails] = useState<IEmail[]>([]);
  const [defaultEmailChecked, setDefaultEmailChecked] = useState(false);

  const goBack = () => {
    closeModal(true);
  };

  const loadingBusiness = businesses.length === 0;
  const loadingArea = areas.length === 0;

  const handleSelectEmail = (
    email: IEmail,
    checkboxType: string,
    checked: boolean,
  ) => {
    const emailIndex = filteredEmails.findIndex(f => f.id === email.id);

    if (emailIndex !== -1) {
      const newSelectedEmail = { ...filteredEmails[emailIndex] };

      if (checkboxType === 'default') {
        if (email.isAssociated && email.isDefault && !checked) {
          newSelectedEmail.isDefault = checked;
          setDefaultEmailChecked(false);
        } else if (
          email.isAssociated &&
          !email.isDefault &&
          checked &&
          defaultEmailChecked === false
        ) {
          newSelectedEmail.isDefault = checked;
          setDefaultEmailChecked(true);
        } else {
          newSelectedEmail.isDefault = email.isDefault;
          toast.warning('Já existe um e-mail marcado como padrão.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }

      if (checkboxType === 'associated') {
        newSelectedEmail.isAssociated = checked;
        newSelectedEmail.isDefault = !checked ? false : email.isDefault;
        if (
          !newSelectedEmail.isAssociated &&
          email.isDefault &&
          defaultEmailChecked
        ) {
          setDefaultEmailChecked(false);
        }
      }

      const newFilteredEmails = [...filteredEmails];
      newFilteredEmails[emailIndex] = newSelectedEmail;

      setFilteredEmails(newFilteredEmails);
    }
  };

  useEffect(() => {
    api.get('/emails').then(response => {
      const data = response?.data;
      const formattedEmails = data.map((email: IEmail) => {
        return {
          id: email.id,
          email: email.email,
          isDefault: false,
          isAssociated: false,
        };
      });
      setEmails(formattedEmails);
    });
  }, []);

  useEffect(() => {
    if (userId)
      api.get(`/users/${userId}`).then(response => {
        setUser(response.data);
        const responseEmails = response.data?.emails || [];
        if (responseEmails.length > 0) {
          const formattedEmails = responseEmails.map((email: IEmail) => {
            return {
              id: email.id,
              email: email.email,
              isDefault: email.isDefault,
              isAssociated: true,
            };
          });
          setUserEmails(formattedEmails);
        }
      });
  }, [userId]);

  useEffect(() => {
    if (emails.length > 0 && userEmails.length > 0) {
      const tempEmails: IEmail[] = emails.map((email: IEmail) => {
        const item: IEmail = userEmails.find(
          (i: IEmail) => i.id === email.id,
        ) as IEmail;

        return {
          id: email.id,
          email: email.email,
          isDefault: item?.isDefault || false,
          isAssociated: item?.isAssociated || false,
        };
      });

      const sortedEmails = tempEmails.sort((a, b) =>
        a.isDefault && !b.isDefault
          ? -1
          : a.isAssociated && !b.isAssociated
          ? -1
          : -b.email.localeCompare(a.email),
      );

      setDefaultEmailChecked(sortedEmails.filter(f => f.isDefault).length > 0);
      setFilteredEmails(sortedEmails);
    } else {
      setFilteredEmails(emails);
    }
  }, [emails, userEmails]);

  useEffect(() => {
    if (isUserAdmin) {
      api.get('/businesses').then(response => setBusinesses(response.data));
    }
  }, [isUserAdmin]);

  const initialValues: IFormData = {
    name: user ? user.name : '',
    email: user ? user.email : '',
    old_password: '',
    password: '',
    confirm_password: '',
    area: user ? user.area : null,
    business: user ? user.business : null,
    profile: user && user?.profile && user.profile.id ? user.profile : null,
    customPermissions: user ? user.customPermissions : [],
    birthDate:
      user && isNotNullish(user?.birthDate) && user?.birthDate.length > 0
        ? formatISOStringToTypeDate(user?.birthDate) || null
        : null,
    phone: user ? user?.phone : null,
    jobTitle: user ? user?.jobTitle || null : null,
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string()
      .email('Digite um email válido')
      .required('Campo obrigatório'),
    birthDate: Yup.string().optional().nullable(),
    phone: Yup.string().optional().nullable(),
    jobTitle: Yup.string().optional().nullable(),
    area: Yup.object().nullable().required('Campo obrigatório'),
    business: Yup.object().nullable().required('Campo obrigatório'),
    profile: Yup.object().nullable().required('Campo obrigatório'),

    old_password: Yup.string(),
    password: Yup.string()
      .min(6, 'Senha deve conter no mínimo 6 caracteres')
      .when('old_password', {
        is: (val: string) => !!val,
        then: Yup.string().required('Campo obrigatório'),
        otherwise: Yup.string(),
      }),

    confirm_password: Yup.string()
      .when('old_password', {
        is: (val: string) => !!val,
        then: Yup.string().required('Campo obrigatório'),
        otherwise: Yup.string(),
      })
      .oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
    customPermissions: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().optional(),
          status: Yup.mixed()
            .oneOf(['DISABLED', 'READ_ONLY', 'READ_AND_WRITE'])
            .optional(),
        }),
      )
      .optional(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formattedEmails = filteredEmails
          .filter(email => email.isAssociated || email.isDefault)
          .map(e => {
            return { id: e.id, isDefault: e.isDefault };
          });
        const formatBithDate =
          isNotNullish(values.birthDate) &&
          values.birthDate.toString().length > 0
            ? formatDateToISOString(values.birthDate.toString())
            : null;
        const newPhone =
          values.phone !== null ? removeNonNumeric(values.phone) : null;

        if (userId) {
          await api.put(`/users/${userId}`, {
            name: values.name,
            email: values.email,
            birthDate: formatBithDate,
            phone: newPhone,
            jobTitle:
              values.jobTitle !== null && values.jobTitle !== ''
                ? values.jobTitle
                : null,
            business_id: isUserAdmin ? values.business?.id : undefined,
            area_id: isUserAdmin ? values.area?.id : undefined,
            profile_id: isUserAdmin ? values.profile?.id : undefined,
            customPermissions: isUserAdmin
              ? values.customPermissions
              : undefined,
            emails: isUserAdmin ? formattedEmails : undefined,

            ...(values.password && isSameUser
              ? {
                  old_password: values.old_password,
                  password: values.password,
                }
              : {}),
          });

          toast.success('Usuário editado com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });

          const useCategory = profilesList.find(
            p => p.id === values.profile?.id,
          )?.category?.name;

          if (
            isSameUser &&
            values.profile?.id &&
            useCategory &&
            useCategory !== userAuth?.profile.categoryName
          ) {
            toast.success(
              'Editado com sucesso. Devido a mudança de categoria de acesso será deslogado.',
              {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              },
            );
            setTimeout(() => {
              signOut();
            }, 3000);
          }
        } else {
          await api.post('/users', {
            name: values.name,
            email: values.email,
            birthDate: formatBithDate,
            phone: values.phone,
            jobTitle: values.jobTitle,
            business_id: values.business?.id,
            area_id: values.area?.id,
            profile_id: values.profile?.id,
            emails: formattedEmails,
          });
          toast.success('Usuário salvo com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            onClose: () => goBack(),
          });
        }
        setSubmitting(true);
      } catch (error: any) {
        setSubmitting(false);

        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  const handleChangePermission = (
    permissionName: string,
    status: PermissionValues,
  ) => {
    const currentCustomPermissions = formik.values.customPermissions;
    if (
      currentCustomPermissions.length > 0 &&
      currentCustomPermissions.find(
        permission => permission.name === permissionName,
      ) !== undefined
    ) {
      const newCustomPermissions = currentCustomPermissions.map(permission => {
        if (permission.name === permissionName) {
          const newStatus =
            status !== permission.status
              ? status
              : status === 'DISABLED' || permission.status === 'READ_AND_WRITE'
              ? 'READ_ONLY'
              : 'DISABLED';

          return {
            ...permission,
            status: newStatus,
          };
        }
        return permission;
      });
      formik.setFieldValue('customPermissions', newCustomPermissions);
    } else {
      formik.setFieldValue('customPermissions', [
        ...currentCustomPermissions,
        { name: permissionName, status },
      ]);
    }
  };

  return {
    formik,
    user_id: userId,
    user,
    areas,
    emails,
    businesses,
    loadingBusiness,
    loadingArea,
    goBack,
    filteredEmails,
    handleSelectEmail,
    defaultEmailChecked,
    profilesList,
    loadingProfiles,
    editPermissionDisabledForUser,
    handleChangePermission,
    permissionProperties,
    permissionsByCategories,
    loadingPermissions,
    isSameUser,
    isUserAdmin,
  } as HookReturn;
}
