/* eslint-disable @typescript-eslint/no-explicit-any */
// src/components/Conditions/Condition.i.ts

import { Dispatch, SetStateAction } from 'react';
import { ICRUDCustomField, ICustomField } from 'src/interface/ICardFields';
import { IGetAccountCustomFieldApi } from 'src/services/accountCustomFieldApi';
import { FixedAccountField } from 'src/utils/fixedAccountFields';
import { integrationFormat } from 'src/utils/integratedFieldUtils';

export interface ITypeField {
  id: string;
  label: string;
}

export type ConditionObject = {
  id?: string;
  type: string;
  operator?: string;
  customField_id?: string;
  accountField_id?: string;
  accountFieldName?: string;
  value?: string;
  phase_ids?: string[];
  task_id?: string;
  taskDone?: boolean;
  // Additional properties specific to triggers
  name?: string;
  progressPercentage?: number;
  phaseEntering?: boolean;
  customFieldValue?: string;
  dispatchType?: string;
  staleCardHours?: number;
};

export type TCondition = string | ConditionObject;

export type ConditionsExpression = Array<Array<TCondition> | string>;

// Type guard function to determine if TCondition is a ConditionObject
export function isConditionObject(
  condition: TCondition,
): condition is ConditionObject {
  return typeof condition === 'object' && condition !== null;
}

export interface IConditionsProps {
  conditionsExpressionInput: ConditionsExpression;
  setConditionsExpressionInput: Dispatch<SetStateAction<ConditionsExpression>>;
  customFields: ICustomField[];
  phases: IPhase[];
  tasks: ITask[];
  accountCustomFields?: IGetAccountCustomFieldApi[];
  isTriggerCondition?: boolean;
  triggerType?: 'scheduled' | 'instant';
}

export interface IGroupStatus {
  index: number;
  isOpen: boolean;
}

export interface IOptionValueProps {
  handleChange: (
    field: string,
    value: string,
    index: number,
    itemIndex: number,
  ) => void;
  handleOpenIntegratedFieldModal?: (
    cardField: ICRUDCustomField,
    groupIndex: number,
    itemIndex: number,
  ) => void;
  customField?: ICustomField | IGetAccountCustomFieldApi | FixedAccountField;
  phases?: IPhase[];
  groupIndex: number;
  itemIndex: number;
  fieldValue: any;
  itemType: string;
  isTriggerCondition?: boolean;
}

export interface IOperatorProps<TItem = ConditionObject> {
  handleConditionChange: (
    field: string,
    value: string,
    index: number,
    itemIndex: number,
    customFieldType?: string,
  ) => void;
  customFields?:
    | ICustomField[]
    | IGetAccountCustomFieldApi[]
    | FixedAccountField[];
  item: TItem;
  groupIndex: number;
  itemIndex: number;
  isGroupCondition?: boolean;
  isTriggerCondition?: boolean;
}

export interface IResponseFillRuleCondition {
  [key: string]:
    | string
    | string[]
    | number
    | boolean
    | undefined
    | { id: string };
  id: string;
  type: string;
  operator: string;
  customField?: {
    id: string;
  };
  accountField?: {
    id: string;
  };
  value?: string;
  phase_ids?: string[];
  task?: { id: string };
  taskDone?: boolean;
  name?: string;
  progressPercentage?: number;
  phaseEntering?: boolean;
  customFieldValue?: string;
  dispatchType?: string;
  staleCardHours?: number;
}

export interface IPhase {
  id: string;
  name: string;
  order: number;
  phase?: {
    name: string;
    id: string;
  };
}

export interface ITask {
  instructions: string;
  phase: {
    name: string;
    id: string;
  };
  isRequired: boolean;
  isInactive: boolean;
  weight: number;
  order: number;
  deadline: number;
  description: string;
  name: string;
  id: string;
}

export interface IIntegratedConditionData {
  cardField: ICustomField;
  cardFieldId: string;
  groupIndex: number;
  itemIndex: number;
  format: integrationFormat;
  dynamicFormat: integrationFormat;
  primaryKeyColumnName: string;
  onClose?: () => void;
}
