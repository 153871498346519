/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { toast } from 'react-toastify';
import { useAuth } from 'src/context/AuthContext';
import { taskEmailConfirmationDialogMessage } from 'src/utils/taskUtils';
import { deepClone } from 'src/utils/deepCloneObject';
import { IObject, TaskModalProps } from './TaskModal.i';
import { TaskTypes } from '../../utils/taskTypes.constants';
import { taskModalStyles } from './taskModalStyles';
import { MemoizedTaskAccountData } from './components/TaskAccountData';
import { MemoizedTaskCardData } from './components/TaskCardData';
import { TaskDetailsData } from './components/TaskDetailsData';
import { TaskLog } from './components/TaskLog';
import { TaskMainData } from './components/TaskMainData';
import { FormikErrorProvider } from '../../context/FormikErrorProvider';
import { TaskSchedulingData } from './components/TaskSchedulingData';
import { Button } from '../ui/button';
import { TaskSkeleton } from './components/TaskSkeleton';
import { TaskConfirmationDialog } from './components/TaskConfirmationDialog';
import { useTaskModal } from './hooks/useTaskModal';

export const TaskModal: React.FC<TaskModalProps> = ({
  task_id,
  closeModal,
  newTaskType,
  isProcessTask,
  card_id,
  associateCard = false,
  cardData,
  dateRangeSuggestion,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...rest
}) => {
  const classes = taskModalStyles();
  const { user } = useAuth();
  const {
    formik,
    loading,
    users,
    accounts,
    accountContacts,
    areas,
    phases,
    cards,
    allUsers,
    taskCurrentTypeId,
    taskDeadline,
    isEmailModalOpen,
    isTaskDoneModalOpen,
    isTaskDone,
    wasInitiallyDone,
    editPermissionDisabledForUser,
    isUserAdmin,
    isUserGuest,
    isUserNotAdminAndDifferentArea,
    isUserNotAccountResponsible,
    cardFieldsErrorList,
    cardTableFieldsErrorList,
    handleRefreshAccounts,
    handleRefreshContacts,
    handleChangeSimpleFieldValue,
    handleChangeAutocompleteFieldValue,
    handleChangeTaskFieldValue,
    handleChangeCardFieldValue,
    handleAddNewLog,
    handleOpenEmailConfirmation,
    handleSendEmail,
    handleFinishTask,
    handleSubmitForm,
    setIsTaskDoneModalOpen,
    setIsEmailModalOpen,
    cardFieldsData,
    cardMainTab,
    cardCurrentPhase,
    fillRuleDictionary,
  } = useTaskModal({
    task_id,
    closeModal,
    newTaskType,
    isProcessTask,
    card_id,
    associateCard,
    cardData,
    dateRangeSuggestion,
  });

  if (taskCurrentTypeId && !TaskTypes[taskCurrentTypeId]) {
    toast.error(`Tipo de tarefa não encontrado`, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
      autoClose: 5000,
    });
    closeModal();
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <FormikErrorProvider
      touched={formik.touched as Record<string, boolean>}
      errors={formik.errors as Record<string, string>}
      hasError={field => !!formik.touched[field] && !!formik.errors[field]}
    >
      {loading ? (
        <TaskSkeleton />
      ) : (
        <form
          noValidate
          className={classes.taskContent}
          onSubmit={handleSubmitForm}
          autoComplete="off"
        >
          {(taskDeadline || formik.values?.wasCreatedByTrigger) && (
            <div className="flex flex-row items-center -my-1.5 py-0 p-2 text-xs font-normal text-gray-400">
              <span>
                {formik.values?.wasCreatedByTrigger
                  ? 'Tarefa Avulsa do Gatilho'
                  : 'Tarefa do Processo'}
              </span>
              {taskDeadline && <span className="ml-auto">{taskDeadline}</span>}
            </div>
          )}
          <TaskMainData
            handleTextValue={handleChangeSimpleFieldValue}
            handleObjectValue={handleChangeAutocompleteFieldValue}
            taskTypeId={taskCurrentTypeId}
            taskUser={formik.values.user}
            users={users}
            name={formik.values.name}
            description={formik.values.description}
            instructions={formik.values.instructions || ''}
            done={isTaskDone}
            isProcessTask={isProcessTask}
            storedUser={user}
            isUserAdmin={isUserAdmin}
            isUserGuest={isUserGuest}
          />
          <TaskSchedulingData
            handleChangeValue={handleChangeSimpleFieldValue}
            startsAt={formik.values.startsAt}
            endsAt={formik.values.endsAt}
            done={isTaskDone}
            editPermissionDisabledForUser={editPermissionDisabledForUser}
            isApprovalType={formik.values.type === 'APPROVAL'}
            wasInitiallyDone={wasInitiallyDone}
          />
          <MemoizedTaskAccountData
            handleChangeValue={handleChangeAutocompleteFieldValue}
            taskTypeId={taskCurrentTypeId}
            accounts={accounts}
            contacts={accountContacts}
            taskAccount={formik.values.account}
            taskContact={formik.values.contacts}
            done={isTaskDone}
            handleRefreshAccounts={handleRefreshAccounts}
            handleRefreshContacts={handleRefreshContacts}
            isProcessTask={isProcessTask}
            editPermissionDisabledForUser={editPermissionDisabledForUser}
            wasCreatedByTrigger={formik.values.wasCreatedByTrigger}
            isUserNotAccountResponsible={isUserNotAccountResponsible}
          />
          <MemoizedTaskCardData
            wasCreatedByTrigger={formik.values.wasCreatedByTrigger}
            taskArea={formik.values.area}
            taskPhase={formik.values.phase}
            taskCard={formik.values.card}
            areas={areas}
            phases={phases}
            cards={cards}
            handleChangeValue={handleChangeAutocompleteFieldValue}
            done={isTaskDone}
            isProcessTask={isProcessTask}
            editPermissionDisabledForUser={editPermissionDisabledForUser}
            isUserNotAdminAndDifferentArea={isUserNotAdminAndDifferentArea}
            isUserNotAccountResponsible={isUserNotAccountResponsible}
          />
          <TaskDetailsData
            task_type={formik.values.type}
            handleChangeTaskFieldValue={handleChangeTaskFieldValue}
            handleObjectValue={handleChangeAutocompleteFieldValue}
            guestUsers={formik.values.guestUsers}
            users={allUsers.filter(
              (user: IObject) => user?.id !== formik?.values?.user?.id,
            )}
            done={isTaskDone}
            fieldValues={formik.values.fieldValues}
            editPermissionDisabledForUser={editPermissionDisabledForUser}
            linkedCardFieldValues={formik.values.linkedCardFieldValues}
            cardFieldsData={cardFieldsData || []}
            cardMainTab={cardMainTab}
            cardCurrentPhase={cardCurrentPhase}
            handleChangeCardFieldValue={handleChangeCardFieldValue}
            cardId={formik.values?.card?.id}
            areaId={formik.values?.area?.id}
            cardFieldsErrorList={cardFieldsErrorList}
            cardTableFieldsErrorList={cardTableFieldsErrorList}
            fillRuleDictionary={fillRuleDictionary}
          />
          <TaskLog
            logs={formik.values.logs}
            isProcessTask={isProcessTask}
            card_id={card_id}
            taskId={formik.values.id}
            handleAddNewLog={handleAddNewLog}
          />
          <Button
            type="submit"
            className="absolute bottom-4 right-8 z-10"
            size="lg"
            onClick={handleOpenEmailConfirmation}
            disabled={editPermissionDisabledForUser}
          >
            Salvar
          </Button>
        </form>
      )}
      {isEmailModalOpen && (
        <TaskConfirmationDialog
          open={isEmailModalOpen}
          setOpen={setIsEmailModalOpen}
          handleReturnSelectedOption={handleSendEmail}
          message={taskEmailConfirmationDialogMessage}
        />
      )}
      {isTaskDoneModalOpen && (
        <TaskConfirmationDialog
          open={isTaskDoneModalOpen}
          setOpen={setIsTaskDoneModalOpen}
          handleReturnSelectedOption={handleFinishTask}
          message="Deseja concluir a tarefa?"
        />
      )}
    </FormikErrorProvider>
  );
};
