import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Container,
  createStyles,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { InputKeyCardMask } from 'src/components/InputKeyCardMask';
import { Button } from 'src/components/ui/button';
import {
  ConditionsExpression,
  isConditionObject,
} from 'src/components/Conditions/Condition.i';
import { ICustomField } from 'src/interface/ICardFields';
import { IGetAccountCustomFieldApi } from 'src/services/accountCustomFieldApi';
import { accountCustomFieldApi } from 'src/services/accountCustomFieldApi';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { IAccount, IContact, IUser, ModalProps, EntityType } from '../Filter.i';
import { styles } from './style';
import { useFilters } from '../../../context/FilterContext';
import { useTempState } from './hooks/useTempState';
import CustomAutoComplete from './components/CustomAutocomplete';
import { FilterConditions } from './components/FilterConditions';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface Filter {
  type: string;
  operator: string;
  field_id: string;
  value: string;
}

function arraysAreEqual(arr1: string[], arr2: string[]) {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export function FilterModal({
  open,
  closeModal,
  title,
  titleLeft,
  selectedArea,
  onConditionsChange,
}: ModalProps): JSX.Element {
  const classes = useStyles();

  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [customFields, setCustomFields] = useState<ICustomField[]>([]);
  const [accountCustomFields, setAccountCustomFields] = useState<
    IGetAccountCustomFieldApi[]
  >([]);

  const {
    handleFilters,
    storedAccountIds,
    storedContactIds,
    storedUserIds,
    storedCardKey,
    storedConditionsExpressionInput,
  } = useFilters();

  const selectedAccountsState = useTempState<IAccount[]>([]);
  const selectedContactsState = useTempState<IContact[]>([]);
  const selectedUsersState = useTempState<IUser[]>([]);
  const cardKeyState = useTempState<string>('');
  const conditionsExpressionInputState = useTempState<ConditionsExpression>([
    [{ name: '', type: '' }],
  ]);

  const prevStoredAccountIdsRef = useRef<string[]>([]);
  const prevStoredContactIdsRef = useRef<string[]>([]);
  const prevStoredUserIdsRef = useRef<string[]>([]);
  const prevStoredCardKeyRef = useRef<string>('');
  const prevStoredConditionsExpressionInputRef = useRef<ConditionsExpression>(
    [],
  );

  const prevAccountsRef = useRef<IAccount[]>([]);
  const prevContactsRef = useRef<IContact[]>([]);
  const prevUsersRef = useRef<IUser[]>([]);

  useEffect(() => {
    if (open) {
      const conditions =
        storedConditionsExpressionInput &&
        storedConditionsExpressionInput.length > 0
          ? storedConditionsExpressionInput
          : [[{ name: '', type: '' }]];
      conditionsExpressionInputState.setTempState(conditions);
    }
  }, [open]);

  useEffect(() => {
    if (open && selectedArea) {
      setLoadingAccounts(true);
      api
        .get('/accounts')
        .then(response => {
          setAccounts(response.data);
          setLoadingAccounts(false);
        })
        .catch(() => setLoadingAccounts(false));
    }
  }, [open, selectedArea]);

  useEffect(() => {
    if (open && selectedAccountsState.tempState.length > 0) {
      setLoadingContacts(true);
      api
        .post('/accounts/contacts', {
          account_ids: selectedAccountsState.tempState.map(
            account => account.id,
          ),
        })
        .then(response => {
          setContacts(response.data || []);
          setLoadingContacts(false);
        })
        .catch(() => setLoadingContacts(false));
    }
  }, [open, selectedAccountsState.tempState]);

  useEffect(() => {
    if (open && selectedArea) {
      setLoadingUsers(true);
      api
        .get(`/areas/${selectedArea.id}/users`)
        .then(response => {
          setUsers(response.data);
          setLoadingUsers(false);
        })
        .catch(() => setLoadingUsers(false));
    }
  }, [open, selectedArea]);

  useEffect(() => {
    if (open && selectedArea) {
      api.get(`/areas/${selectedArea.id}/customFields`).then(response => {
        setCustomFields(response.data);
      });
    }
  }, [open, selectedArea]);

  useEffect(() => {
    const getAccountCustomFields = async () => {
      if (open && selectedArea) {
        try {
          const response = await accountCustomFieldApi.getCustomFields();
          setAccountCustomFields(response?.data || []);
        } catch (err: unknown) {
          let errorMessage = 'Falha ao buscar campos personalizados';
          if (err instanceof Error) {
            errorMessage += `: ${err.message}`;
          }
          toast.error(`${errorMessage}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
          setAccountCustomFields([]);
        }
      }
    };
    getAccountCustomFields();
  }, [open, selectedArea]);

  useEffect(() => {
    if (open) {
      if (
        !arraysAreEqual(storedAccountIds, prevStoredAccountIdsRef.current) ||
        accounts !== prevAccountsRef.current
      ) {
        prevStoredAccountIdsRef.current = storedAccountIds;
        prevAccountsRef.current = accounts;
        const storedAccounts = accounts.filter(a =>
          storedAccountIds.includes(a.id),
        );
        selectedAccountsState.setTempState(storedAccounts);
      }

      if (
        !arraysAreEqual(storedContactIds, prevStoredContactIdsRef.current) ||
        contacts !== prevContactsRef.current
      ) {
        prevStoredContactIdsRef.current = storedContactIds;
        prevContactsRef.current = contacts;
        const storedContacts = contacts.filter(a =>
          storedContactIds.includes(a.id),
        );
        selectedContactsState.setTempState(storedContacts);
      }

      if (
        !arraysAreEqual(storedUserIds, prevStoredUserIdsRef.current) ||
        users !== prevUsersRef.current
      ) {
        prevStoredUserIdsRef.current = storedUserIds;
        prevUsersRef.current = users;
        const storedUsers = users.filter(a => storedUserIds.includes(a.id));
        selectedUsersState.setTempState(storedUsers);
      }

      if (storedCardKey !== prevStoredCardKeyRef.current) {
        prevStoredCardKeyRef.current = storedCardKey;
        cardKeyState.setTempState(storedCardKey);
      }

      if (
        JSON.stringify(storedConditionsExpressionInput) !==
        JSON.stringify(prevStoredConditionsExpressionInputRef.current)
      ) {
        prevStoredConditionsExpressionInputRef.current =
          storedConditionsExpressionInput;
        const conditions =
          storedConditionsExpressionInput &&
          storedConditionsExpressionInput.length > 0
            ? storedConditionsExpressionInput
            : [[{ name: '', type: '' }]];
        conditionsExpressionInputState.setTempState(conditions);
      }
    }
  }, [
    open,
    storedAccountIds,
    storedContactIds,
    storedUserIds,
    storedCardKey,
    storedConditionsExpressionInput,
    accounts,
    contacts,
    users,
  ]);

  const cleanConditions = (
    conditionsExpressionInput: ConditionsExpression,
  ): ConditionsExpression => {
    const cleanedConditions = conditionsExpressionInput
      .map(conditionGroup => {
        if (Array.isArray(conditionGroup)) {
          const cleanedGroup = conditionGroup.filter(condition => {
            if (typeof condition !== 'string' && condition) {
              const hasType = !!condition.type;
              const hasField =
                (condition.type === 'customField' &&
                  !!condition.customField_id) ||
                (condition.type === 'accountField' &&
                  !!condition.accountField_id) ||
                (condition.type === 'accountFieldFixed' &&
                  !!condition.accountFieldName);
              const hasOperator = !!condition.operator;
              const hasValue = !!condition.value?.trim();
              return hasType && hasField && hasOperator && hasValue;
            }
            return false;
          });
          return cleanedGroup.length > 0 ? cleanedGroup : null;
        }
        return null;
      })
      .filter(group => group !== null) as ConditionsExpression;

    // Ensure cleanedConditions is not empty
    if (!cleanedConditions || cleanedConditions.length === 0) {
      return [[{ name: '', type: '' }]];
    }

    return cleanedConditions;
  };

  const applyFilters = () => {
    selectedAccountsState.applyTempState();
    selectedContactsState.applyTempState();
    selectedUsersState.applyTempState();
    cardKeyState.applyTempState();

    const cleanedConditions = cleanConditions(
      conditionsExpressionInputState.tempState,
    );

    conditionsExpressionInputState.setTempState(cleanedConditions);

    const filters: Filter[] = [];
    const conditionFilters = transformConditions(cleanedConditions);
    const allFilters: Filter[] = [...filters, ...conditionFilters];

    const account_ids = selectedAccountsState.tempState.map(
      account => account.id,
    );
    const contact_ids = selectedContactsState.tempState.map(
      contact => contact.id,
    );
    const user_ids = selectedUsersState.tempState.map(user => user.id);
    const card_key = cardKeyState.tempState;

    onConditionsChange(cleanedConditions);

    handleFilters({
      area_id: selectedArea ? selectedArea.id : '',
      filters: allFilters,
      conditionsExpressionInput: cleanedConditions,
      account_ids,
      contact_ids,
      user_ids,
      card_key,
    });

    closeModal(true);
  };

  const resetFilters = () => {
    selectedAccountsState.resetTempState();
    selectedContactsState.resetTempState();
    selectedUsersState.resetTempState();
    cardKeyState.resetTempState();

    conditionsExpressionInputState.setTempState([[{ name: '', type: '' }]]);

    onConditionsChange([[{ name: '', type: '' }]]);

    handleFilters({
      area_id: selectedArea ? selectedArea.id : '',
      filters: [],
      conditionsExpressionInput: [[{ name: '', type: '' }]],
      account_ids: [],
      contact_ids: [],
      user_ids: [],
      card_key: '',
    });

    closeModal(true);
  };

  const transformConditions = (
    conditionsExpressionInput: ConditionsExpression,
  ): Filter[] => {
    const filters: Filter[] = [];

    conditionsExpressionInput.forEach(conditionGroup => {
      if (Array.isArray(conditionGroup)) {
        conditionGroup.forEach(condition => {
          if (isConditionObject(condition)) {
            let type: string | undefined;
            let field_id: string | undefined;

            if (condition.type === 'customField') {
              type = 'CARD_FIELD';
              field_id = condition.customField_id;
            } else if (condition.type === 'accountField') {
              type = 'ACCOUNT_FIELD';
              field_id = condition.accountField_id;
            } else if (condition.type === 'accountFieldFixed') {
              type = 'ACCOUNT_FIELD_FIXED';
              field_id = condition.accountFieldName;
            }

            if (
              type &&
              field_id &&
              condition.operator &&
              condition.value?.trim()
            ) {
              filters.push({
                type,
                operator: condition.operator,
                field_id,
                value: condition.value,
              });
            }
          }
        });
      }
    });

    return filters;
  };

  function handleChange(
    value: EntityType[],
    type: 'IAccount' | 'IContact' | 'IUser',
  ) {
    if (type === 'IAccount') {
      selectedAccountsState.setTempState(value as IAccount[]);
      selectedContactsState.setTempState([]);
    }
    if (type === 'IContact') {
      selectedContactsState.setTempState(value as IContact[]);
    }
    if (type === 'IUser') {
      selectedUsersState.setTempState(value as IUser[]);
    }
  }

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      onClose={() => closeModal(false)}
      aria-labelledby={title}
      maxWidth={false}
      disableEnforceFocus
    >
      <DialogTitle id={title}>
        <Box marginTop="10px" justifyContent="space-between">
          <Box height="22px">
            <span>{title}</span>
          </Box>
          <Box>
            <span style={{ fontWeight: 'bolder' }}>{titleLeft}</span>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.children}>
        <Container>
          <Box className={classes.content}>
            <CustomAutoComplete
              options={accounts}
              loading={loadingAccounts}
              selectedOptions={selectedAccountsState.tempState}
              setCardKey={cardKeyState.setTempState}
              handleChange={handleChange}
              label="Contas"
              type="IAccount"
            />
            <CustomAutoComplete
              options={
                selectedAccountsState.tempState.length > 0 ? contacts : []
              }
              loading={loadingContacts}
              selectedOptions={selectedContactsState.tempState}
              setCardKey={cardKeyState.setTempState}
              handleChange={handleChange}
              label="Contatos"
              type="IContact"
            />
            <CustomAutoComplete
              options={users}
              loading={loadingUsers}
              selectedOptions={selectedUsersState.tempState}
              setCardKey={cardKeyState.setTempState}
              handleChange={handleChange}
              label="Usuários"
              type="IUser"
            />
            <TextField
              id="key"
              name="key"
              label="Chave"
              margin="dense"
              variant="outlined"
              autoComplete="off"
              fullWidth
              className={
                cardKeyState.tempState.length > 0
                  ? classes.muiInputSelected
                  : classes.muiInput
              }
              value={cardKeyState.tempState}
              onChange={e => {
                cardKeyState.setTempState(e.target.value);
                selectedAccountsState.setTempState([]);
                selectedContactsState.setTempState([]);
                selectedUsersState.setTempState([]);
              }}
              placeholder="2209000001"
              InputProps={{
                inputComponent: InputKeyCardMask as never,
              }}
            />
            <FilterConditions
              conditionsExpressionInput={
                conditionsExpressionInputState.tempState
              }
              setConditionsExpressionInput={
                conditionsExpressionInputState.setTempState
              }
              customFields={customFields}
              accountCustomFields={accountCustomFields}
            />
          </Box>
          <Box
            display="flex"
            flexWrap="nowrap"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            position="absolute"
            bottom="15px"
            right="22px"
          >
            <Button variant="secondary" color="inherit" onClick={resetFilters}>
              Limpar
            </Button>
            <Button className="ml-6" onClick={applyFilters}>
              Aplicar
            </Button>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
