import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { type Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import { DialogDescription } from '@radix-ui/react-dialog';
import { useAuth } from 'src/context/AuthContext';
import { Button } from '../ui/button';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { TooltipWrapper } from '../ui/tooltip';

interface AvatarUploaderProps {
  avatarUrl?: string;
}

export const AvatarUploader = ({ avatarUrl }: AvatarUploaderProps) => {
  const { updateAvatarUrl } = useAuth();
  const maxFinalSize =
    Number(process.env.REACT_APP_AVATAR_MAX_SIZE) || 200 * 1024;
  const [imgDialogOpen, setImgDialogOpen] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [croppedFile, setCroppedFile] = useState<File | null>(null);
  const [crop, setCrop] = useState<Crop | undefined>(undefined);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    // Reset crop state when image changes
    if (image) {
      setCrop({
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25,
      });
    }
  }, [image]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    if (!allowedTypes.includes(file.type)) {
      toast.error('Selecione um arquivo de imagem (PNG, JPG, JPEG).', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        theme: 'colored',
      });
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      toast.error('Tamanho da imagem excede 2MB', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        theme: 'colored',
      });
      return;
    }

    // Create an image element to check the dimensions
    const img = new Image();
    img.onload = () => {
      const maxWidth = 4000; // in pixels
      const maxHeight = 4000; // in pixels

      // Check if the image dimensions exceed the max allowed size
      if (img.width > maxWidth || img.height > maxHeight) {
        toast.error(`Dimensões máximas da imagem ${maxWidth}x${maxHeight}.`, {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          theme: 'colored',
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result as string);
      reader.readAsDataURL(file);
    };

    // Trigger image load by creating an object URL
    img.src = URL.createObjectURL(file);
    setImgDialogOpen(true);
  };

  const handleSaveCroppedImage = () => {
    if (
      !imageRef.current ||
      !canvasRef.current ||
      crop === undefined ||
      !crop.width ||
      !crop.height
    ) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;

    const cropX = crop.x ? crop.x * scaleX : 0;
    const cropY = crop.y ? crop.y * scaleY : 0;
    const cropWidth = crop.width * scaleX;
    const cropHeight = crop.height * scaleY;

    // Set canvas dimensions to a higher resolution for better quality
    const outputSize = 400;
    canvas.width = outputSize;
    canvas.height = outputSize;

    // Enable high-quality image smoothing
    if (ctx !== null) {
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
    }

    // Draw the cropped area onto the canvas
    ctx?.drawImage(
      imageRef.current,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      outputSize,
      outputSize,
    );

    // Convert the canvas content to Blob
    canvas.toBlob(
      blob => {
        if (!blob) return;
        const croppedFile = new File([blob], 'avatar.jpg', {
          type: 'image/jpeg',
        });
        setCroppedFile(croppedFile);
        setCroppedImage(URL.createObjectURL(blob));
      },
      'image/jpeg',
      1.0,
    );

    setImgDialogOpen(false);
  };

  const handleCropChange = (newCrop: Crop) => {
    setCrop(newCrop);
  };

  const handleImageLoaded = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    const img = event.target as HTMLImageElement;
    imageRef.current = img;
  };

  const uploadFile = async () => {
    const formData = new FormData();
    if (!croppedFile) return;

    if (croppedFile.size > maxFinalSize) {
      toast.error(
        `Imagem final excede o limite de ${Math.round(maxFinalSize / 1000)}KB.`,
        {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          theme: 'colored',
        },
      );
      return;
    }

    formData.append('avatar', croppedFile);

    api
      .patch('/users/avatar', formData)
      .then(response => {
        const { url } = response.data;
        if (url) {
          toast.success('Foto atualizada com sucesso.', {
            position: 'top-right',
            autoClose: 2000,
            closeOnClick: true,
            theme: 'colored',
          });

          const timestampedUrl = `${url}?${new Date().getTime()}`;
          updateAvatarUrl(timestampedUrl);
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(`Erro ao atualizar foto. ${errMessage}`, {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          theme: 'colored',
        });
      });
  };

  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="flex flex-row gap-2">
        <Dialog
          open={imgDialogOpen}
          onOpenChange={open => {
            if (!open) setImgDialogOpen(false);
          }}
        >
          <TooltipWrapper
            value="Após selecionar uma imagem, clique no avatar para editar."
            className="font-medium"
          >
            <DialogTrigger
              asChild
              onClick={() => {
                if (image) setImgDialogOpen(true);
              }}
              disabled={!image || !croppedImage}
            >
              <Avatar className="self-end cursor-pointer">
                <AvatarImage src={croppedImage || image || avatarUrl || ''} />
                <AvatarFallback>AV</AvatarFallback>
              </Avatar>
            </DialogTrigger>
          </TooltipWrapper>
          <DialogContent className="w-fit h-fit z-[1400]">
            <DialogHeader className="p-0 m-0">
              <DialogTitle className="p-0 m-0">Editar Foto</DialogTitle>
              <DialogDescription>Recorte a área da imagem</DialogDescription>
            </DialogHeader>
            <div className="min-w-[300px] min-h-[300px] w-fit h-fit max-w-[400px] max-h-[450px] overflow-auto flex items-center justify-center">
              <ReactCrop
                crop={crop}
                onChange={handleCropChange}
                aspect={1}
                className="m-auto w-full h-full"
              >
                <img
                  src={image || ''}
                  onLoad={handleImageLoaded}
                  alt="Upload"
                  className="object-contain w-full h-full"
                />
              </ReactCrop>
            </div>
            <DialogFooter>
              <Button
                onClick={handleSaveCroppedImage}
                type="button"
                className="w-full"
              >
                Recortar
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <div className="grid w-full max-w-fit items-center gap-1">
          <Label htmlFor="userAvatar">Escolher Foto</Label>
          <Input
            id="userAvatar"
            type="file"
            onChange={handleFileChange}
            accept="image/png, image/jpeg, image/jpg"
            className="cursor-pointer"
          />
        </div>
      </div>
      <Button
        onClick={uploadFile}
        className="w-full"
        type="button"
        disabled={!croppedImage || !image}
      >
        Salvar edição
      </Button>
      <canvas ref={canvasRef} className="hidden" />
    </div>
  );
};
