/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import { useHistory } from 'react-router-dom';

import { ProfileCategory } from 'src/services/permissionsApi';
import { toast } from 'react-toastify';
import { isNotNullish } from 'src/utils/comparisonUtils';
import api from '../services/api';

export interface IStoredUser {
  id: string;
  name: string;
  email: string;
  areaId: string;
  profile: {
    categoryName: ProfileCategory;
    name: string;
  };
  permissions: {
    [Permission: string]: 'READ_AND_WRITE' | 'READ_ONLY' | 'DISABLED';
  };
  avatarUrl: string | null;
  isFirstLogin: boolean;
}

interface AuthState {
  token: string;
  user: IStoredUser;
  company: {
    id: string;
    name: string;
    fileSize: number;
  };
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IStoredUser;
  company: {
    id: string;
    name: string;
    fileSize: number;
  };
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IStoredUser): void;
  updateAvatarUrl(url: string): void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const company = localStorage.getItem('@Brasao:company');
    const token = localStorage.getItem('@Brasao:token');
    const user = localStorage.getItem('@Brasao:user:v2');
    const oldUser = localStorage.getItem('@Brasao:user');

    if (oldUser) {
      localStorage.removeItem('@Brasao:user');
    }

    if (token && user && company) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user), company: JSON.parse(company) };
    }

    return {} as AuthState;
  });

  // axios intercept and validate expired jwt token (401)
  // axios intercept and validate forbidden access (403)
  useEffect(() => {
    try {
      const token = localStorage.getItem('@Brasao:token');
      if (token) {
        toast.dismiss();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        api.interceptors.response.use(undefined, (error: any) => {
          if (error.response) {
            const status = error.response.status || '';
            const statusText = error.response.statusText || '';
            const message = error.response.data.message || '';
            const errMessage: string = statusText || message || '';
            const isTokenExpired =
              message === 'Invalid JWT token' ||
              statusText === 'Invalid JWT token';

            if (status === 401) {
              if (
                isTokenExpired ||
                errMessage.toLowerCase().includes('inativo')
              ) {
                signOut();
                history.push('/');
              }
            } else if (status === 403) {
              toast.error('Acesso negado', {
                position: 'top-right',
                autoClose: 5000,
                closeOnClick: true,
                theme: 'colored',
              });
              history.push('/');
            }
          }
          return Promise.reject(error);
        });
      }
    } catch (err) {
      console.error(err);
      history.push('/');
      localStorage.removeItem('@Brasao:token');
      localStorage.removeItem('@Brasao:user:v2');
      localStorage.removeItem('@Brasao:company');
      localStorage.removeItem('@Brasao:area');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const response = await api.post('sessions', {
          email,
          password,
        });

        const { token, user, company } = response.data;

        if (token && isNotNullish(user) && isNotNullish(company)) {
          localStorage.setItem('@Brasao:token', token);
          localStorage.setItem('@Brasao:user:v2', JSON.stringify(user));
          localStorage.setItem('@Brasao:company', JSON.stringify(company));

          api.defaults.headers.authorization = `Bearer ${token}`;
          setData({ token, user, company });
          history.push('/workspace');
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        let defaultMessage = 'Ocorreu um erro.';

        if (error?.response) {
          if (error.response.status === 401 && error.response?.data?.message) {
            if (error.response.data.message === 'Email ou senha incorretos.') {
              defaultMessage =
                'Credenciais inválidas. Verifique seus dados e tente novamente.';
            } else {
              defaultMessage = error.response.data.message;
            }
          } else {
            defaultMessage += error.response.data?.message
              ? ` ${error.response.data.message}`
              : '';
          }
        } else if (error.request) {
          defaultMessage =
            'Não foi possível se conectar ao servidor. Tente novamente mais tarde.';
        } else {
          defaultMessage = `Erro inesperado: ${error.message ?? ''}`.trim();
        }

        toast.error(defaultMessage, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@Brasao:token');
    localStorage.removeItem('@Brasao:user');
    localStorage.removeItem('@Brasao:user:v2');
    localStorage.removeItem('@Brasao:company');
    localStorage.removeItem('@Brasao:area');

    setData({} as AuthState);
  }, []);

  useEffect(() => {
    if (
      data?.user?.permissions === undefined ||
      data?.user?.profile === undefined
    ) {
      signOut();
    }
  }, [data.user]);

  const updateUser = useCallback(
    (user: IStoredUser) => {
      localStorage.setItem('@Brasao:user:v2', JSON.stringify(user));

      setData({
        token: data.token,
        user,
        company: data.company,
      });
    },
    [data.token],
  );

  const updateAvatarUrl = (url: string) => {
    localStorage.setItem(
      '@Brasao:user:v2',
      JSON.stringify({ ...data.user, avatarUrl: url }),
    );

    setData(oldValue => ({
      ...oldValue,
      user: {
        ...oldValue.user,
        avatarUrl: url,
      },
    }));
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user as IStoredUser,
        company: data.company as {
          id: string;
          name: string;
          fileSize: number;
        },
        signIn,
        signOut,
        updateUser,
        updateAvatarUrl,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
